import * as React from 'react';

function NoiseNoAnimationSlide2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="nosie_slide_2__clip-path">
          <rect
            className="nosie_slide_2__cls-1"
            x={737.23}
            y={247.12}
            width={51.7}
            height={27.62}
            rx={8.03}
          />
        </clipPath>
        <clipPath id="nosie_slide_2__clip-path-2">
          <circle className="nosie_slide_2__cls-1" cx={773.76} cy={260.51} r={10.68} />
        </clipPath>
        <clipPath id="nosie_slide_2__clip-path-3">
          <path
            className="nosie_slide_2__cls-2"
            d="M578.75 634.36l.09 2.58L600 692.52a1.26 1.26 0 002.38-.09l16.74-56.84-.08-2.58z"
          />
        </clipPath>
        <linearGradient
          id="nosie_slide_2__linear-gradient"
          x1={1004.58}
          y1={145.17}
          x2={1003.27}
          y2={157.04}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#80cfd3" />
          <stop offset={1} stopColor="#cff" />
        </linearGradient>
        <linearGradient
          id="nosie_slide_2__linear-gradient-2"
          x1={764.86}
          y1={621.62}
          x2={764.86}
          y2={696.78}
          gradientTransform="rotate(-1.92 -1154.393 1597.476)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#80cfd3" />
          <stop offset={1} stopColor="#c0ffff" />
        </linearGradient>
        <style>
          {
            '.nosie_slide_2__cls-1,.nosie_slide_2__cls-18,.nosie_slide_2__cls-20,.nosie_slide_2__cls-24,.nosie_slide_2__cls-25,.nosie_slide_2__cls-5{fill:none}.nosie_slide_2__cls-2{fill:#efb45e}.nosie_slide_2__cls-21,.nosie_slide_2__cls-22{opacity:.5}.nosie_slide_2__cls-14{mix-blend-mode:multiply}.nosie_slide_2__cls-20,.nosie_slide_2__cls-24,.nosie_slide_2__cls-25,.nosie_slide_2__cls-5{stroke:#ffe26e;stroke-linecap:round;stroke-linejoin:round}.nosie_slide_2__cls-5{stroke-width:2px}.nosie_slide_2__cls-8{fill:#4d81ba}.nosie_slide_2__cls-9{fill:#6bb8ed}.nosie_slide_2__cls-11{fill:#5d9fd3}.nosie_slide_2__cls-12{fill:#ffe26e}.nosie_slide_2__cls-14{fill:#615297;opacity:.3}.nosie_slide_2__cls-16{fill:#7dd3f4}.nosie_slide_2__cls-18{stroke:#5d9fd3;stroke-miterlimit:10;stroke-width:2.09px}.nosie_slide_2__cls-20{stroke-width:2.59px}.nosie_slide_2__cls-21{fill:#d9498f}.nosie_slide_2__cls-22{fill:#ed6bb0}.nosie_slide_2__cls-23{fill:#f7c6d2}.nosie_slide_2__cls-24{stroke-width:3.34px}.nosie_slide_2__cls-25{stroke-width:2.01px}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="nosie_slide_2__Layer_1" data-name="Layer 1">
          <g id="nosie_slide_2__FLOAT">
            <g id="nosie_slide_2__people_noise">
              <path
                className="nosie_slide_2__cls-5"
                d="M1090.5 422.56l12.94 1.66"
              />
              <path
                className="nosie_slide_2__cls-5"
                d="M1094.96 409.61l11.3 6.41"
              />
              <path
                className="nosie_slide_2__cls-5"
                d="M1104.11 399.32l7.95 10.18"
              />
            </g>
            <g
              id="nosie_slide_2__helicopter-2"
              data-name="helicopter"
            >
              <g id="nosie_slide_2__helicopter_B-2" data-name="helicopter B">
                <path
                  fill="url(#nosie_slide_2__linear-gradient)"
                  d="M983.17 158.32l39.1 7.49v-7.49l-5.08-7.5-4.57-3.2-23.6-5.54-5.85 16.24z"
                />
                <path
                  className="nosie_slide_2__cls-8"
                  transform="rotate(-12.16 1001.987 171.102)"
                  d="M1001.06 166.49h1.6v9.16h-1.6z"
                />
                <path
                  className="nosie_slide_2__cls-8"
                  transform="rotate(-51.08 979.958 167.443)"
                  d="M975.41 166.63h9.16v1.6h-9.16z"
                />

                <rect
                  className="nosie_slide_2__cls-8"
                  x={983.75}
                  y={128.87}
                  width={15.03}
                  height={2.72}
                  rx={1.16}
                  transform="rotate(-80.55 991.317 130.192)"
                />
                <path
                  className="nosie_slide_2__cls-9"
                  d="M1020.27 153.06a19 19 0 00-12.32-7.68L975 139.9l-2.15 12.91a10.25 10.25 0 008.42 11.79l37.25 6.19a4.36 4.36 0 005-3.59 18.92 18.92 0 00-3.25-14.14zM999.05 155a4 4 0 01-4.64 3.31l-2-.34a4 4 0 01-3.31-4.63l.84-5.1a4 4 0 014.64-3.31l2 .33a4 4 0 013.31 4.64zm21.36 7.64l-10.55-1.76a8.68 8.68 0 01-7.14-10l.39-2.34a2.18 2.18 0 012.5-1.79l4.24.71a7 7 0 012.63 1 23.72 23.72 0 014.79 4.13 18.76 18.76 0 014.44 8.7 1.14 1.14 0 01-1.3 1.31z"
                />
                <path
                  className="nosie_slide_2__cls-9"
                  d="M927.29 131.96l49.48 8.23-2.23 13.35-48.51-14.02 1.26-7.56z"
                />
                <path
                  className="nosie_slide_2__cls-9"
                  d="M922.11 127.13a7.79 7.79 0 106.41 9 7.78 7.78 0 00-6.41-9zm4.11 8.58a5.48 5.48 0 01-.82 2.09l-3-2a1.52 1.52 0 00.11-.38 1.41 1.41 0 000-.29l3.79-.76a5.67 5.67 0 01-.08 1.34zm-.06-2.08l-3.82.76a1.79 1.79 0 00-.38-.56l2.22-3.33a5.5 5.5 0 011.98 3.13zm-4.43-4.2a5.39 5.39 0 011.84.66l-2.22 3.33a1.66 1.66 0 00-.38-.11 1.42 1.42 0 00-.29 0l-.77-3.85a5.29 5.29 0 011.82-.03zm-2.54.18l.77 3.82a2 2 0 00-.57.38l-3.08-2.05a5.48 5.48 0 012.88-2.15zm-.33 5.48l-3.4.69a5.28 5.28 0 010-1.86 5.21 5.21 0 01.49-1.52l3 2a1.45 1.45 0 00-.11.37 1.46 1.46 0 00.02.32zm-3.22 1.4l3.36-.68a1.73 1.73 0 00.38.57l-1.85 2.78a5.48 5.48 0 01-1.89-2.67zm4.3 3.71a5.31 5.31 0 01-1.79-.64l1.84-2.77a1.66 1.66 0 00.38.11h.29l.67 3.33a5.43 5.43 0 01-1.39-.03zm2.12-.07l-.68-3.36a1.68 1.68 0 00.57-.38l3 2a5.41 5.41 0 01-2.89 1.74z"
                />
                <path
                  className="nosie_slide_2__cls-9"
                  d="M926.5 130.22l-5.12-1.43-5.63 1.09 1.51-15.88a2.25 2.25 0 012.52-2.11l3.15.53a2.16 2.16 0 011.76 1.94zM984.34 134.32l20.69 3.44a6.28 6.28 0 015.16 7.21l-.28 1.66-31.71-5.27.5-3a4.9 4.9 0 015.64-4.04z"
                />
                <circle
                  className="nosie_slide_2__cls-8"
                  cx={920.83}
                  cy={134.81}
                  r={5.46}
                />
                <path
                  d="M922.36 135.78l3 2a5.39 5.39 0 01-.45.59l-3-2a1.68 1.68 0 01-.57.38l.68 3.36a4.45 4.45 0 01-.73.12l-.67-3.33h-.29a1.66 1.66 0 01-.38-.11l-1.84 2.77a4.38 4.38 0 01-.62-.4l1.85-2.78a1.73 1.73 0 01-.38-.57l-3.36.68a5.68 5.68 0 01-.18-.71l3.4-.69a1.46 1.46 0 010-.29 1.45 1.45 0 01.11-.37l-3-2a5.07 5.07 0 01.37-.64l3.08 2.05a2 2 0 01.57-.38l-.77-3.82a4.2 4.2 0 01.72-.17l.77 3.85a1.42 1.42 0 01.29 0 1.66 1.66 0 01.38.11l2.22-3.33a5 5 0 01.61.41l-2.17 3.32a1.79 1.79 0 01.38.56l3.82-.76a5.68 5.68 0 01.11.72l-3.79.76a1.41 1.41 0 010 .29 1.52 1.52 0 01-.16.38z"
                  fill="#bb7fc9"
                />
                <path
                  className="nosie_slide_2__cls-9"
                  d="M925.4 137.8l.42.29-.4.61-.47-.31-3-2a1.68 1.68 0 01-.57.38l.68 3.36.14.73-.72.14-.15-.75-.67-3.33h-.29a1.66 1.66 0 01-.38-.11l-1.84 2.77-.47.7-.61-.41.46-.69 1.85-2.78a1.73 1.73 0 01-.38-.57l-3.36.68-.73.15-.14-.73.69-.13 3.4-.69a1.46 1.46 0 010-.29 1.45 1.45 0 01.11-.37l-3-2-.42-.28.41-.61.38.25 3.08 2.05a2 2 0 01.57-.38l-.77-3.82-.05-.26.72-.15v.24l.77 3.85a1.42 1.42 0 01.29 0 1.66 1.66 0 01.38.11l2.22-3.33.09-.14.61.41-.09.14-2.16 3.3a1.79 1.79 0 01.38.56l3.82-.76.27-.06.14.72-.3.06-3.79.76a1.41 1.41 0 010 .29 1.52 1.52 0 01-.11.38z"
                />
                <rect
                  className="nosie_slide_2__cls-9"
                  x={914.28}
                  y={120.09}
                  width={2.54}
                  height={8.15}
                  rx={1.08}
                  transform="rotate(-80.55 915.595 124.13)"
                />
                <rect
                  className="nosie_slide_2__cls-11"
                  x={988.38}
                  y={154.71}
                  width={2.81}
                  height={37}
                  rx={1.2}
                  transform="rotate(-80.55 989.826 173.18)"
                />
                <g>
                  <ellipse
                    className="propeller"
                    cx={991.97}
                    cy={123.63}
                    rx={5.34}
                    ry={51.58}
                    transform="rotate(-80.55 992.017 123.598)"
                    fill="#b0e0ff"
                  />
                </g>
              </g>
              <path
                className="nosie_slide_2__cls-12 helicopter_noise"
                d="M1062.15 134.61a10 10 0 00-8.25-11.51 1.51 1.51 0 00-1.8 1 1.47 1.47 0 001 1.8 9.22 9.22 0 011.57.38l.37.14.14.06c.23.12.46.24.68.37s.44.28.65.42-.12-.11.11.09l.3.25c.19.17.37.35.54.53s.18.19.26.29l.16.19c-.08-.11-.1-.12 0 0a9.07 9.07 0 01.8 1.3c0 .12.11.23.16.35s0 .08 0-.06a1.34 1.34 0 00.07.18c.08.25.16.49.23.74a6.43 6.43 0 01.17.76V133.61c0 .13-.06.54 0 .23a1.51 1.51 0 001 1.8 1.48 1.48 0 001.81-1z"
              />
              <path
                className="nosie_slide_2__cls-12 helicopter_noise"
                d="M1073.4 133.9a17.77 17.77 0 00-14.82-20.32 1.51 1.51 0 00-1.8 1 1.48 1.48 0 001 1.8 15.18 15.18 0 019.86 5.72 15 15 0 012.91 11 1.51 1.51 0 001 1.8 1.48 1.48 0 001.81-1z"
              />
            </g>
            <g id="nosie_slide_2__air_con-2" data-name="air con">
              <rect
                className="nosie_slide_2__cls-8"
                x={740.27}
                y={275.79}
                width={13.79}
                height={2.67}
                rx={1.07}
                transform="rotate(-45 747.16 277.126)"
              />
              <rect
                className="nosie_slide_2__cls-8"
                x={769.41}
                y={275.79}
                width={13.79}
                height={2.67}
                rx={1.07}
                transform="rotate(-45 776.303 277.135)"
              />
              <g clipPath="url(#nosie_slide_2__clip-path)">
                <rect
                  className="nosie_slide_2__cls-9"
                  x={737.23}
                  y={247.12}
                  width={51.7}
                  height={27.62}
                  rx={8.03}
                />
                <path
                  className="nosie_slide_2__cls-14"
                  d="M731.45 246.68h20.6v28.5h-20.6z"
                />
              </g>
              <g clipPath="url(#nosie_slide_2__clip-path-2)">
                <circle
                  className="nosie_slide_2__cls-11"
                  cx={773.76}
                  cy={260.51}
                  r={10.68}
                />
                <path
                  className="nosie_slide_2__cls-16"
                  d="M758.24 252.82h27.1v1.45h-27.1zM758.24 256.63h27.1v1.45h-27.1zM758.24 260.44h27.1v1.45h-27.1zM758.24 264.26h27.1v1.45h-27.1zM758.24 268.07h27.1v1.45h-27.1z"
                />
                <path
                  id="nosie_slide_2__shadow"
                  className="nosie_slide_2__cls-14"
                  d="M785.16 260.51a10.68 10.68 0 01-10.68 10.68c-.33 0-.66 0-1-.05a10.67 10.67 0 000-21.25 8.22 8.22 0 011-.06 10.68 10.68 0 0110.68 10.68z"
                />
              </g>
              <path
                className="nosie_slide_2__cls-11"
                d="M754.15 251.53h5.31v4.74h-5.31z"
              />
              <g className="air_con_noise">
                <path
                  className="nosie_slide_2__cls-5"
                  d="M795.44,250.49c2.84,0,2.84,2.09,5.67,2.09s2.83-2.09,5.66-2.09,2.83,2.09,5.66,2.09,2.84-2.09,5.67-2.09,2.83,2.09,5.67,2.09"
                />
                <path
                  className="nosie_slide_2__cls-5"
                  d="M802,259.47c2.83,0,2.83,2.09,5.67,2.09s2.83-2.09,5.65-2.09,2.84,2.09,5.67,2.09,2.83-2.09,5.67-2.09,2.83,2.09,5.67,2.09"
                />
                <path
                  className="nosie_slide_2__cls-5"
                  d="M795.44,270.08c2.84,0,2.84,2.09,5.67,2.09s2.83-2.09,5.66-2.09,2.83,2.09,5.66,2.09,2.84-2.09,5.67-2.09,2.83,2.09,5.67,2.09"
                />
              </g>
            </g>
            <g id="nosie_slide_2__vehicle_animate-2" data-name="vehicle_animate">
              <g id="nosie_slide_2__vehicle_top-2" data-name="vehicle_top">
                <rect
                  className="nosie_slide_2__cls-11"
                  x={743.62}
                  y={590.86}
                  width={78.55}
                  height={45.19}
                  rx={10.04}
                  transform="rotate(-1.92 783.353 613.65)"
                />
                <rect
                  className="nosie_slide_2__cls-9"
                  x={743.57}
                  y={590.87}
                  width={78.55}
                  height={42.29}
                  rx={9.71}
                  transform="rotate(-1.92 783.353 613.65)"
                />
                <g
                  id="nosie_slide_2__vehicle_arm-2"
                  data-name="vehicle_arm"
                >
                  <rect
                    className="nosie_slide_2__cls-11"
                    x={586.91}
                    y={579.27}
                    width={22.94}
                    height={57}
                    rx={6.22}
                    transform="rotate(-1.92 598.696 607.87)"
                  />
                  <rect
                    className="nosie_slide_2__cls-11"
                    x={582.69}
                    y={587.75}
                    width={22.94}
                    height={37.54}
                    rx={6.22}
                    transform="rotate(-1.92 594.533 606.905)"
                  />
                  <path
                    className="nosie_slide_2__cls-2"
                    d="M578.75 634.36l.09 2.58L600 692.52a1.26 1.26 0 002.38-.09l16.74-56.84-.08-2.58z"
                  />
                  <g clipPath="url(#nosie_slide_2__clip-path-3)">
                    <path
                      className="nosie_slide_2__cls-2"
                      d="M578.53 627.91l.31 9L600 692.52a1.26 1.26 0 002.38-.09l16.74-56.84-.3-9z"
                    />
                    <path
                      className="nosie_slide_2__cls-12"
                      transform="rotate(-46.92 593.342 675.065)"
                      d="M592.59 627.14h1.47v95.85h-1.47z"
                    />
                    <path
                      className="nosie_slide_2__cls-12"
                      transform="rotate(-46.92 592.854 660.478)"
                      d="M592.1 612.54h1.47v95.85h-1.47z"
                    />
                    <path
                      className="nosie_slide_2__cls-12"
                      transform="rotate(-46.92 592.366 645.89)"
                      d="M591.61 597.95h1.47v95.85h-1.47z"
                    />
                    <path
                      className="nosie_slide_2__cls-12"
                      transform="rotate(-46.92 591.879 631.303)"
                      d="M591.12 583.36h1.47v95.85h-1.47z"
                    />
                    <path
                      className="nosie_slide_2__cls-12"
                      transform="rotate(-46.92 591.385 616.7)"
                      d="M590.64 568.77h1.47v95.85h-1.47z"
                    />
                  </g>
                  <path
                    className="nosie_slide_2__cls-9"
                    d="M583.14 626.87l31-1a4.65 4.65 0 014.8 4.48l.17 5.28-40.29 1.35-.18-5.27a4.64 4.64 0 014.5-4.84z"
                  />
                  <rect
                    className="nosie_slide_2__cls-9"
                    x={676.03}
                    y={535.61}
                    width={27.11}
                    height={101.5}
                    rx={11.53}
                    transform="rotate(-42.86 689.497 586.324)"
                  />
                  <rect
                    className="nosie_slide_2__cls-9"
                    x={578.38}
                    y={566.68}
                    width={101.5}
                    height={27.11}
                    rx={11.53}
                    transform="rotate(-38.84 629.121 580.228)"
                  />
                  <g>
                    <circle
                      className="nosie_slide_2__cls-9"
                      cx={661.87}
                      cy={555.91}
                      r={18.07}
                    />
                    <circle
                      className="nosie_slide_2__cls-18"
                      cx={661.87}
                      cy={555.91}
                      r={14.6}
                    />
                  </g>
                  <g>
                    <circle
                      className="nosie_slide_2__cls-9"
                      cx={597.09}
                      cy={606.03}
                      r={18.07}
                    />
                    <circle
                      className="nosie_slide_2__cls-18"
                      cx={597.09}
                      cy={606.03}
                      r={14.6}
                    />
                  </g>
                  <circle
                    className="nosie_slide_2__cls-9"
                    cx={724.38}
                    cy={621.33}
                    r={18.07}
                  />
                </g>
                <path
                  className="nosie_slide_2__cls-11"
                  d="M729.28 637.78l31.44-1a5.72 5.72 0 005.52-5.9l-2.14-63.62a5.71 5.71 0 00-5.9-5.52l-46.2 1.5a5.72 5.72 0 00-5.33 7.19l16.86 63.12a5.71 5.71 0 005.75 4.23z"
                />
                <path
                  fill="url(#nosie_slide_2__linear-gradient-2)"
                  d="M722.99 631.38l34.88-1.17-1.99-59.4-48.63 1.63 15.74 58.94z"
                />
              </g>
              <g id="nosie_slide_2__noise_linse">
                <path
                  className="nosie_slide_2__cls-20"
                  d="M569.3 690.69l12.8.56"
                />
                <path
                  className="nosie_slide_2__cls-20"
                  d="M572.27 678.58l11.61 5.43"
                />
                <path
                  className="nosie_slide_2__cls-20"
                  d="M579.64 668.54l8.65 9.45"
                />
              </g>
              <g id="nosie_slide_2__noise_linse-2" data-name="noise_linse">
                <path
                  className="nosie_slide_2__cls-20"
                  d="M621.97 663.98l-7.61 10.3"
                />
                <path
                  className="nosie_slide_2__cls-20"
                  d="M631.16 674.06l-10.98 6.61"
                />
                <path
                  className="nosie_slide_2__cls-20"
                  d="M635.78 686.9l-12.67 1.9"
                />
              </g>
              <g id="nosie_slide_2__vehicle_light">
                <rect
                  className="nosie_slide_2__cls-11"
                  x={730.49}
                  y={546.79}
                  width={14.42}
                  height={7.97}
                  rx={3.39}
                  transform="rotate(-1.92 738.138 551.11)"
                />
                <rect
                  className="nosie_slide_2__cls-21"
                  x={716.46}
                  y={541.35}
                  width={34.15}
                  height={18.86}
                  rx={8.02}
                  // transform="translate(-18.05 24.9)"
                />
                <rect
                  className="nosie_slide_2__cls-21"
                  x={705.59}
                  y={535.34}
                  width={55.89}
                  height={30.87}
                  rx={13.13}
                  transform="rotate(-1.92 733.96 551.04)"
                />
                <rect
                  className="nosie_slide_2__cls-22"
                  x={721.55}
                  y={544.14}
                  width={24.63}
                  height={13.61}
                  rx={5.79}
                  transform="rotate(-1.92 734.258 551.045)"
                />
                <rect
                  className="nosie_slide_2__cls-23"
                  x={726.58}
                  y={546.93}
                  width={14.42}
                  height={7.97}
                  rx={3.39}
                  transform="rotate(-1.92 733.96 551.04)"
                />
              </g>
            </g>
            <g id="nosie_slide_2__amulance_light">
              <rect
                className="nosie_slide_2__cls-11"
                x={444.11}
                y={454.68}
                width={13.89}
                height={7.67}
                rx={3.56}
              />
              <rect
                className="nosie_slide_2__cls-21"
                x={430.6}
                y={449.29}
                width={32.89}
                height={18.17}
                rx={8.42}
              />
              <rect
                className="nosie_slide_2__cls-21"
                x={420.13}
                y={443.51}
                width={53.84}
                height={29.74}
                rx={13.79}
              />
              <rect
                className="nosie_slide_2__cls-22"
                x={435.5}
                y={452}
                width={23.73}
                height={13.11}
                rx={6.08}
              />
              <rect
                className="nosie_slide_2__cls-23"
                x={440.35}
                y={454.68}
                width={13.89}
                height={7.67}
                rx={3.56}
              />
            </g>
            <g id="nosie_slide_2__amulance_noise_arrow">
              <path
                className="nosie_slide_2__cls-24"
                d="M505.22,414.85s5.89-18.29-4.18-41.69c-10.44-24.24-105.13-70.73-108.58-87.48s92.68-92.93,99.58-112.2-69.41-93.62-69.41-93.62"
              />
              <g>
                <line className="nosie_slide_2__cls-24" x1="448.93" y1="79.86" x2="422.63" y2="79.86" />
                <line className="nosie_slide_2__cls-24" x1="422.63" y1="106.16" x2="422.63" y2="79.86" />
              </g>
            </g>
            <g id="nosie_slide_2__amulance_noise_lines">
              <path
                className="nosie_slide_2__cls-25"
                d="M490.64 416.61l-2.34 12.11"
              />
              <path
                className="nosie_slide_2__cls-25"
                d="M506.61 427.16l-10.22 6.91"
              />
              <path
                className="nosie_slide_2__cls-25"
                d="M510.45 445.92l-12.12-2.35"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NoiseNoAnimationSlide2;
