import * as React from 'react';
import { motion } from 'framer-motion';

function NoiseAnimationSlide6(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="noise_slide_6__clip-path">
          <rect
            className="noise_slide_6__cls-1"
            x={95.81}
            y={181.77}
            width={66.18}
            height={30.19}
            rx={15.1}
            transform="rotate(-14 133.05 199.246)"
          />
        </clipPath>
        <clipPath id="noise_slide_6__clip-path-2">
          <path
            className="noise_slide_6__cls-1"
            d="M140.38 187.39a8 8 0 116 9.65 8 8 0 01-6-9.65"
          />
        </clipPath>
        <clipPath id="noise_slide_6__clip-path-3">
          <ellipse
            id="noise_slide_6__eyes"
            className="noise_slide_6__cls-2"
            cx={326.85}
            cy={503.27}
            rx={7.44}
            ry={6.87}
          />
        </clipPath>
        <clipPath id="noise_slide_6__clip-path-4">
          <ellipse
            id="noise_slide_6__eyes-2"
            data-name="eyes"
            className="noise_slide_6__cls-2"
            cx={295.91}
            cy={503.27}
            rx={7.44}
            ry={6.87}
          />
        </clipPath>
        <style>
          {
            '.noise_slide_6__cls-1{fill:none}.noise_slide_6__cls-2{fill:#fff}.noise_slide_6__cls-5{fill:#a573cc}.noise_slide_6__cls-6{fill:#ed6bb0}.noise_slide_6__cls-8{fill:#ffe26e}.noise_slide_6__cls-10{fill:#eb826a}.noise_slide_6__cls-13{fill:#414042}.noise_slide_6__cls-19{fill:#72db9c}.noise_slide_6__cls-20{fill:#d39ee5}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="noise_slide_6__Layer_1" data-name="Layer 1">
          <g id="noise_slide_6__Animals_shadows" data-name="Animals shadows">
            <g id="noise_slide_6__small_bird" data-name="small bird">
              <path
                className="noise_slide_6__cls-5"
                d="M617.91 223.13h2.7v10.58h-2.7zM612.45 217.83h2.7v10.58h-2.7z"
              />
              <path
                className="noise_slide_6__cls-6"
                d="M589.78 172.46h11.83a37.66 37.66 0 0137.67 37.67v10.2a2.79 2.79 0 01-2.79 2.8h-24.21a26.67 26.67 0 01-26.67-26.67v-19.83a4.17 4.17 0 014.17-4.17z"
              />
              <path
                d="M610.22 193.38h6.94a22.11 22.11 0 0122.12 22.11v6a1.64 1.64 0 01-1.64 1.64h-14.21a15.66 15.66 0 01-15.66-15.66v-11.65a2.45 2.45 0 012.45-2.44z"
                fill="#d9498f"
              />
              <rect
                className="noise_slide_6__cls-8"
                x={568.92}
                y={182.8}
                width={20.11}
                height={4.99}
                rx={2.49}
              />
              <rect
                x={594.78}
                y={178.13}
                width={4.25}
                height={7.58}
                rx={2.12}
                fill="#3f3461"
              />
            </g>
            <motion.g
              animate={{
                x: [0, 100, -200, 100, 0],
                y: [0, 100, 1100, 0],
              }}
              id="noise_slide_6__bee-2"
              data-name="bee"
              transition={{
                duration: 20, repeat: Infinity,
              }}
            >
              <path
                className="noise_slide_6__cls-10"
                d="M140.1 217.42l2.77-.68.03-8.08-2.77.68-.03 8.08zM134.85 218.71l2.77-.68.02-8.08-2.77.68-.02 8.08zM122.68 221.69l2.76-.68.03-8.07-2.77.68-.02 8.07zM117.42 222.98l2.77-.68.02-8.07-2.77.68-.02 8.07z"
              />
              <rect
                x={95.81}
                y={181.77}
                width={66.18}
                height={30.19}
                rx={15.1}
                transform="rotate(-14 133.05 199.246)"
                fill="#fbec6d"
              />
              <g clipPath="url(#noise_slide_6__clip-path)">
                <path
                  className="noise_slide_6__cls-13"
                  d="M171.71 199a12.91 12.91 0 01-21.8 0l-1.41.9a14.57 14.57 0 0024.62 0z"
                />
              </g>
              <g clipPath="url(#noise_slide_6__clip-path-2)">
                <path
                  d="M140.38 187.39a8 8 0 116 9.65 8 8 0 01-6-9.65"
                  fill="#f1f2f2"
                />
                <path
                  className="noise_slide_6__cls-13"
                  d="M148.15 189.16a8 8 0 116 9.65 8 8 0 01-6-9.65"
                />
              </g>
              <path
                d="M119 215.07l11.34-3 9.9-2.6-7.68-29.2-11.06 2.91-10.18 2.67z"
                fill="#f99c43"
              />
              <motion.g
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
              >
                <path
                  className="noise_slide_6__cls-10"
                  d="M79.84 162l2.66-.7a31.1 31.1 0 0138 22.18l-2.65.7a31.12 31.12 0 01-38-22.18z"
                />
              </motion.g>
              <motion.g
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
              >
                <path
                  d="M138.14 143.83l.7 2.65a31.11 31.11 0 01-22.18 38l-.7-2.66a31.1 31.1 0 0122.18-38z"
                  fill="#f5b1a6"
                />
              </motion.g>
            </motion.g>
            <motion.g
              animate={{
                x: [60, -150],
                y: [20, -50],
                opacity: [0, 1, 1, 0],
                scale: [0, 1],
              }}
              transition={{
                duration: 6, repeat: Infinity, ease: 'linear',
              }}
              id="noise_slide_6__music_note"
            >
              <path
                stroke="#ffe26e"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3.05}
                fill="none"
                d="M544.06 178.69l-5.56-23.07-20.61 6.58 5.35 23.66"
              />
              <circle
                className="noise_slide_6__cls-8"
                cx={518.97}
                cy={186.09}
                r={5.83}
              />
              <circle
                className="noise_slide_6__cls-8"
                cx={539.73}
                cy={179.43}
                r={5.83}
              />
            </motion.g>
            <motion.g
              animate={{
                x: [60, -150],
                y: [20, -50],
                opacity: [0, 1, 1, 0],
                scale: [0, 1],
              }}
              transition={{
                delay: 2, duration: 6, repeat: Infinity, ease: 'linear',
              }}
              id="noise_slide_6__music_note"
            >
              <path
                stroke="#ffe26e"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3.05}
                fill="none"
                d="M544.06 178.69l-5.56-23.07-20.61 6.58 5.35 23.66"
              />
              <circle
                className="noise_slide_6__cls-8"
                cx={518.97}
                cy={186.09}
                r={5.83}
              />
              <circle
                className="noise_slide_6__cls-8"
                cx={539.73}
                cy={179.43}
                r={5.83}
              />
            </motion.g>
            <motion.g
              animate={{
                x: [60, -150],
                y: [20, -50],
                opacity: [0, 1, 1, 0],
                scale: [0, 1],
              }}
              transition={{
                delay: 4, duration: 6, repeat: Infinity, ease: 'linear',
              }}
              id="noise_slide_6__music_note"
            >
              <path
                stroke="#ffe26e"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3.05}
                fill="none"
                d="M544.06 178.69l-5.56-23.07-20.61 6.58 5.35 23.66"
              />
              <circle
                className="noise_slide_6__cls-8"
                cx={518.97}
                cy={186.09}
                r={5.83}
              />
              <circle
                className="noise_slide_6__cls-8"
                cx={539.73}
                cy={179.43}
                r={5.83}
              />
            </motion.g>
            <path
              className="noise_slide_6__cls-19"
              d="M333.92 568.61l20.4-10.61c-4.25-7-11.58-11.43-19-14.88a122.61 122.61 0 00-18.55-6.89c-7.73-2.18-16.27-3.54-23.59-.24a28.14 28.14 0 00-8.85 6.71 47 47 0 00-10.76 19c-1 3.35-1.53 7-.26 10.26 2 5.06 7.63 7.48 12.77 9.24a293 293 0 0029.4 8.34c8.06 1.84 16.31 3.35 24.56 2.8a26.61 26.61 0 0011-2.79c5.41-3 9-8.51 11-14.33a73.91 73.91 0 00-28.87-5.64"
            />
            <g id="noise_slide_6__frog-2" data-name="frog">
              <path
                className="noise_slide_6__cls-5"
                d="M332.67 556.84H332a21.57 21.57 0 008.68-17.08 1 1 0 00-1.05-1H335c-10.69 0-19.39 8-19.39 17.9V561a1 1 0 001.05 1c.61 0 1.22 0 1.82-.07a3.18 3.18 0 00.62.07h13.61a2.59 2.59 0 100-5.16z"
              />
              <path
                className="noise_slide_6__cls-20"
                d="M323.8 506.57h-16.95C292 506.57 280 517.7 280 531.38V561a1 1 0 001 1h41.25a7 7 0 007.25-6.7v-43.43a5.54 5.54 0 00-5.7-5.3z"
              />
              <path
                d="M328.79 518.86a1.08 1.08 0 00-1.17.39c-.06.07-5.44 7.35-14.56 8-8.89.58-12.61-7.44-12.77-7.78a1 1 0 00-1-.6c-7.59 0-13.77 5.7-13.77 12.71V561a1 1 0 001.05 1h35.67a7 7 0 007.25-6.7v-35.52a1 1 0 00-.7-.92z"
                fill="#e9b3f3"
              />
              <g id="noise_slide_6__eye_frog-3" data-name="eye frog">
                <rect
                  className="noise_slide_6__cls-20"
                  x={317.32}
                  y={494.65}
                  width={18.84}
                  height={17.4}
                  rx={8.7}
                />
                <ellipse
                  id="noise_slide_6__eyes-3"
                  data-name="eyes"
                  className="noise_slide_6__cls-2"
                  cx={326.85}
                  cy={503.27}
                  rx={7.44}
                  ry={6.87}
                />
                <g clipPath="url(#noise_slide_6__clip-path-3)">
                  <ellipse
                    id="noise_slide_6__eyes-4"
                    data-name="eyes"
                    cx={331.22}
                    cy={501.34}
                    rx={7.44}
                    ry={6.87}
                  />
                </g>
              </g>
              <path
                className="noise_slide_6__cls-5"
                d="M297.08 556.84h-.66a21.55 21.55 0 008.69-17.08 1 1 0 00-1.05-1h-4.69c-10.69 0-19.39 8-19.39 17.9V561a1 1 0 001 1c.61 0 1.22 0 1.82-.07a3.1 3.1 0 00.62.07h13.61a2.59 2.59 0 100-5.16z"
              />
              <path
                className="noise_slide_6__cls-5"
                d="M320.11 492.68a1.76 1.76 0 01-.31 0 1 1 0 01-.69-1.21 8.74 8.74 0 018.44-5.55c6.49 0 8.35 4.67 8.43 4.86a1 1 0 01-.64 1.24 1.08 1.08 0 01-1.34-.59c-.05-.14-1.48-3.58-6.45-3.58a6.68 6.68 0 00-6.44 4.19 1 1 0 01-1 .64z"
                id="noise_slide_6__eyebrows"
              />
              <g id="noise_slide_6__eye_frog-4" data-name="eye frog">
                <rect
                  className="noise_slide_6__cls-20"
                  x={286.38}
                  y={494.65}
                  width={18.84}
                  height={17.4}
                  rx={8.7}
                />
                <ellipse
                  id="noise_slide_6__eyes-5"
                  data-name="eyes"
                  className="noise_slide_6__cls-2"
                  cx={295.91}
                  cy={503.27}
                  rx={7.44}
                  ry={6.87}
                />
                <g clipPath="url(#noise_slide_6__clip-path-4)">
                  <ellipse
                    id="noise_slide_6__eyes-6"
                    data-name="eyes"
                    cx={300.29}
                    cy={501.34}
                    rx={7.44}
                    ry={6.87}
                  />
                </g>
              </g>
              <path
                className="noise_slide_6__cls-5"
                d="M289.17 492.68a1.76 1.76 0 01-.31 0 1 1 0 01-.69-1.21 8.75 8.75 0 018.44-5.55c6.49 0 8.35 4.67 8.43 4.86a1 1 0 01-.64 1.24 1.08 1.08 0 01-1.34-.59c-.05-.14-1.48-3.58-6.45-3.58a6.68 6.68 0 00-6.44 4.19 1 1 0 01-1 .64z"
                id="noise_slide_6__eyebrows-2"
                data-name="eyebrows"
              />
              <rect
                className="noise_slide_6__cls-20"
                x={306.21}
                y={550.7}
                width={7.08}
                height={12.86}
                rx={3.54}
              />
              <rect
                className="noise_slide_6__cls-20"
                x={321.51}
                y={550.7}
                width={7.08}
                height={12.86}
                rx={3.54}
              />
            </g>
            <path
              className="noise_slide_6__cls-19"
              d="M241.23 603.34l13.12-6.84c-2.73-4.48-7.44-7.35-12.2-9.57a78.2 78.2 0 00-11.94-4.44c-5-1.4-10.46-2.28-15.17-.15a18.17 18.17 0 00-5.7 4.31 30.34 30.34 0 00-6.92 12.26 10.37 10.37 0 00-.17 6.6c1.27 3.25 4.91 4.81 8.22 5.94a190.34 190.34 0 0018.91 5.37c5.19 1.18 10.5 2.15 15.81 1.8a16.94 16.94 0 007.08-1.8c3.48-1.89 5.77-5.47 7.08-9.22a47.59 47.59 0 00-18.58-3.6"
            />
            <path
              className="noise_slide_6__cls-6"
              d="M241.07 587.87h-2.79a13.18 13.18 0 00-8.33 3 9.44 9.44 0 00-2.13-3.26l-1.37-1.37a.88.88 0 00-1.24 0l-1.37 1.37a9.55 9.55 0 00-2.15 3.31 13.21 13.21 0 00-8.4-3h-2.79a.87.87 0 00-.87.87v2.78a13.28 13.28 0 0013.26 13.26h5.79a13.28 13.28 0 0013.26-13.26v-2.78a.87.87 0 00-.87-.92z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NoiseAnimationSlide6;
