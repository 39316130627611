import * as React from 'react';

function NoiseNoAnimationSlide5(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="noise_slide_5__clip-path">
          <path
            id="noise_slide_5__eyes"
            className="noise_slide_5__cls-1"
            d="M1126.36 142a7 7 0 01-7.58 5.22 5.44 5.44 0 01-4.46-6.92 7 7 0 017.53-5.27 5.43 5.43 0 014.51 6.97z"
          />
        </clipPath>
        <style>
          {
            '.noise_slide_5__cls-1{fill:#fff}.noise_slide_5__cls-4{fill:#d08448}.noise_slide_5__cls-5{fill:#efb45e}.noise_slide_5__cls-6{fill:#ffe26e}.noise_slide_5__cls-7{fill:#fff0b3}.noise_slide_5__cls-9{fill:#ed6bb0}.noise_slide_5__cls-10{fill:#ffb0cb}.noise_slide_5__cls-11{fill:#ff6bb3}.noise_slide_5__cls-12{fill:#eed7ff}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="noise_slide_5__Layer_1" data-name="Layer 1">
          <g id="noise_slide_5__FLOAT">
            <g
              id="noise_slide_5__bird-2"
              data-name="bird"
            >
              <path
                className="noise_slide_5__cls-4"
                d="M1076.67 181.16l8.52-10.49a1.06 1.06 0 011.47-.15l.55.44a1.06 1.06 0 01.39.71 1.07 1.07 0 01-.23.77l-8.54 10.49a1.07 1.07 0 01-.7.38 1.08 1.08 0 01-.56-.1 1.2 1.2 0 01-.21-.13l-.54-.44a1 1 0 01-.15-1.48zM1091.09 170.93a1 1 0 011.46-.16l.56.45a1 1 0 01.16 1.48l-8.66 10.55a1.06 1.06 0 01-1.26.28 1.28 1.28 0 01-.21-.14l-.55-.44a1.09 1.09 0 01-.38-.71 1.07 1.07 0 01.23-.77z"
              />
              <g>
                <path
                  className="noise_slide_5__cls-5"
                  d="M1089.71 126.69c6.89-12.09 18.41-21.23 30.82-24.45a1 1 0 01.93.2 1.07 1.07 0 01.38.88l-3.79 55.14a1.05 1.05 0 01-1.22 1l-32.75-5.49a1.22 1.22 0 01-.27-.09 1.06 1.06 0 01-.6-.93v-1.92a47.66 47.66 0 016.5-24.34z"
                />
              </g>
              <path
                className="noise_slide_5__cls-6"
                d="M1100.48 136.63l28.43-3.89a1 1 0 01.82.25 1 1 0 01.36.77l.36 19.64v1.09a20.66 20.66 0 01-18.34 19.26l-45.11 4.52a1.07 1.07 0 01-.56-.09 1.19 1.19 0 01-.23-.16 1 1 0 01-.36-.75 36.85 36.85 0 01.86-9.63 40.8 40.8 0 0133.77-31.01z"
              />
              <path
                className="noise_slide_5__cls-7"
                d="M1098.15 157l31.12-3.63a1 1 0 01.83.27 1.05 1.05 0 01.34.81 20.66 20.66 0 01-18.34 19.26l-29 2.91a1.19 1.19 0 01-.56-.1 1.11 1.11 0 01-.24-.16 1.06 1.06 0 01-.35-.75 18.51 18.51 0 0116.2-18.61z"
              />
              <g>
                <path
                  className="noise_slide_5__cls-6"
                  d="M1098.05 99.76a1.07 1.07 0 01.94-.08 1 1 0 01.62.7l13.7 51a1.07 1.07 0 01-.14.84 1.06 1.06 0 01-.71.47L1079 158a1.05 1.05 0 01-1.15-.69l-.62-1.74a43.71 43.71 0 01-1.15-24.17 50.88 50.88 0 0121.97-31.64z"
                />
              </g>
              <g id="noise_slide_5__general_eye_shape-2" data-name="general eye shape">
                <g id="noise_slide_5__EYE-2" data-name="EYE">
                  <path
                    id="noise_slide_5__eyes-2"
                    data-name="eyes"
                    className="noise_slide_5__cls-1"
                    d="M1126.36 142a7 7 0 01-7.58 5.22 5.44 5.44 0 01-4.46-6.92 7 7 0 017.53-5.27 5.43 5.43 0 014.51 6.97z"
                  />
                  <g clipPath="url(#noise_slide_5__clip-path)">
                    <path
                      id="noise_slide_5__eyes-3"
                      data-name="eyes"
                      d="M1130.4 140.75a7.12 7.12 0 01-7.65 5.26 5.46 5.46 0 01-4.49-6.95 7.13 7.13 0 017.6-5.31 5.45 5.45 0 014.54 7z"
                    />
                  </g>
                </g>
              </g>
              <path
                id="noise_slide_5__n\xE6b"
                className="noise_slide_5__cls-4"
                d="M1140.32 140l-10.11 1.31-.08-4.72 10.13-1.35a1.94 1.94 0 012.3 1.89v.39a2.58 2.58 0 01-2.24 2.48z"
              />
            </g>
            <g
              className="fly"
            >
              <g>
                <path
                  className="noise_slide_5__cls-5"
                  d="M155.76 106.24l-1.33 12.09 9.13-.81 1.34-12.23a3.86 3.86 0 00-1-3.16 3.72 3.72 0 00-3.14-1.07 6 6 0 00-5 5.18z"
                />
              </g>
              <g>
                <path
                  className="noise_slide_5__cls-7"
                  d="M149.64 106.79l4.49 11.57 9.12-.82-4.53-11.69a6.43 6.43 0 00-2.56-3 5.92 5.92 0 00-3.79-1c-2.51.23-3.68 2.48-2.73 4.94z"
                />
              </g>
              <path
                className="noise_slide_5__cls-6"
                d="M152.05 120.91a3.13 3.13 0 003.48 2.72l7.1-.64a3.27 3.27 0 002.19-1.08 2.93 2.93 0 00.75-2.21 3.13 3.13 0 00-3.48-2.71l-7.09.63a3.35 3.35 0 00-2.2 1.09 2.91 2.91 0 00-.75 2.2z"
              />
            </g>
            <g
              className="fly"
            >
              <g>
                <path
                  className="noise_slide_5__cls-5"
                  d="M204.64 134.35l-1.33 12.1 9.13-.82 1.34-12.22a3.85 3.85 0 00-1-3.16 3.72 3.72 0 00-3.14-1.07 6 6 0 00-5 5.17z"
                />
              </g>
              <g>
                <path
                  className="noise_slide_5__cls-7"
                  d="M198.52 134.9l4.48 11.57 9.13-.81L207.6 134a6.52 6.52 0 00-2.56-3 6 6 0 00-3.79-1c-2.51.19-3.68 2.45-2.73 4.9z"
                />
              </g>
              <path
                className="noise_slide_5__cls-6"
                d="M200.93 149a3.12 3.12 0 003.48 2.71l7.1-.63a3.36 3.36 0 002.19-1.08 2.91 2.91 0 00.75-2.21 3.14 3.14 0 00-3.45-2.69l-7.09.64a3.34 3.34 0 00-2.19 1.08 2.9 2.9 0 00-.79 2.18z"
              />
            </g>
            <g
              className="fly"
            >
              <g>
                <path
                  className="noise_slide_5__cls-5"
                  d="M260.22 110.87L258.89 123l9.13-.82 1.34-12.22a3.91 3.91 0 00-1-3.17 3.7 3.7 0 00-3.14-1.06 6 6 0 00-5 5.14z"
                />
              </g>
              <g>
                <path
                  className="noise_slide_5__cls-7"
                  d="M254.1 111.42l4.49 11.58 9.13-.81-4.54-11.7a6.47 6.47 0 00-2.56-3 6 6 0 00-3.79-1c-2.5.22-3.68 2.51-2.73 4.93z"
                />
              </g>
              <path
                className="noise_slide_5__cls-6"
                d="M256.52 125.55a3.11 3.11 0 003.47 2.71l7.1-.63a3.3 3.3 0 002.19-1.09 2.87 2.87 0 00.75-2.2 3.12 3.12 0 00-3.48-2.72l-7.09.64a3.25 3.25 0 00-2.19 1.08 2.86 2.86 0 00-.75 2.21z"
              />
            </g>
            <g id="noise_slide_5__pink_flies" data-name="pink flies">
              <g transform="translate(-1000, -150)">
                <animateMotion
                  dur="2s"
                  repeatCount="indefinite"
                  path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
                />
                <g>
                  <path
                    className="noise_slide_5__cls-9"
                    d="M1081 337.64l-1.27 11.36 8.56-.76 1.26-11.47a3.65 3.65 0 00-.92-3 3.46 3.46 0 00-2.94-1 5.63 5.63 0 00-4.69 4.87z"
                  />
                </g>
                <g>
                  <path
                    className="noise_slide_5__cls-10"
                    d="M1075.23 338.15l4.21 10.86 8.57-.77-4.26-11a6.13 6.13 0 00-2.4-2.84 5.67 5.67 0 00-3.56-.95c-2.35.28-3.45 2.4-2.56 4.7z"
                  />
                </g>
                <path
                  className="noise_slide_5__cls-11"
                  d="M1077.49 351.41a3 3 0 003.27 2.55l6.66-.6a3.07 3.07 0 002-1 2.72 2.72 0 00.71-2.07 3 3 0 00-3.27-2.55l-6.66.6a3.09 3.09 0 00-2 1 2.72 2.72 0 00-.71 2.07z"
                />
              </g>
              <g>
                <g>
                  <path
                    className="noise_slide_5__cls-9"
                    d="M1142 301.37l-1.25 11.34 8.56-.76 1.26-11.47a3.61 3.61 0 00-.91-3 3.48 3.48 0 00-2.95-1 5.62 5.62 0 00-4.71 4.89z"
                  />
                </g>
                <g>
                  <path
                    className="noise_slide_5__cls-10"
                    d="M1136.21 301.88l4.22 10.86 8.57-.74-4.26-11a6.13 6.13 0 00-2.4-2.84 5.65 5.65 0 00-3.56-1c-2.36.3-3.46 2.42-2.57 4.72z"
                  />
                </g>
                <path
                  className="noise_slide_5__cls-11"
                  d="M1138.48 315.14a2.94 2.94 0 003.26 2.55l6.66-.6a3.12 3.12 0 002.06-1 2.71 2.71 0 00.7-2.07 2.94 2.94 0 00-3.26-2.55l-6.66.6a3.12 3.12 0 00-2.06 1 2.71 2.71 0 00-.7 2.07z"
                />
              </g>
            </g>
            <path
              className="noise_slide_5__cls-12 cloud"
              d="M178.88 231.41a20.15 20.15 0 0118.33-20.07 20.15 20.15 0 0119.48-15h2.65a20.15 20.15 0 0119.45 15h5.77a20.15 20.15 0 0120.13 20.14V233.55a19.69 19.69 0 01-1 6.24 3.16 3.16 0 01-3 2.16h-77.83a3.16 3.16 0 01-3-2.16 19.69 19.69 0 01-1-6.24v-1c0-.3.02-.79.02-1.14z"
            />
            <path
              className="noise_slide_5__cls-12 cloud"
              d="M178.88 231.41a20.15 20.15 0 0118.33-20.07 20.15 20.15 0 0119.48-15h2.65a20.15 20.15 0 0119.45 15h5.77a20.15 20.15 0 0120.13 20.14V233.55a19.69 19.69 0 01-1 6.24 3.16 3.16 0 01-3 2.16h-77.83a3.16 3.16 0 01-3-2.16 19.69 19.69 0 01-1-6.24v-1c0-.3.02-.79.02-1.14z"
            />
            <path
              className="noise_slide_5__cls-12 cloud"
              d="M595.31 119.49a15.43 15.43 0 0114.07-15.39 15.43 15.43 0 0114.93-11.53h2A15.46 15.46 0 01641.27 104h4.42a15.47 15.47 0 0115.45 15.45V121.06a15.3 15.3 0 01-.76 4.79 2.42 2.42 0 01-2.3 1.65h-59.72a2.41 2.41 0 01-2.29-1.65 15 15 0 01-.76-4.79v-.8-.77z"
            />
            <path
              className="noise_slide_5__cls-12 cloud"
              d="M595.31 119.49a15.43 15.43 0 0114.07-15.39 15.43 15.43 0 0114.93-11.53h2A15.46 15.46 0 01641.27 104h4.42a15.47 15.47 0 0115.45 15.45V121.06a15.3 15.3 0 01-.76 4.79 2.42 2.42 0 01-2.3 1.65h-59.72a2.41 2.41 0 01-2.29-1.65 15 15 0 01-.76-4.79v-.8-.77z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NoiseNoAnimationSlide5;
