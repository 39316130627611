import * as React from 'react';
import { motion } from 'framer-motion';

function NoiseAnimationSlide3(props) {
  return (
    <svg
      id="noise_slide_3__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="noise_slide_3__clip-path">
          <ellipse
            className="noise_slide_3__cls-1"
            cx={233.46}
            cy={176.37}
            rx={7.09}
            ry={6.81}
            transform="rotate(-73.57 233.466 176.374)"
          />
        </clipPath>
        <clipPath id="noise_slide_3__clip-path-2">
          <ellipse
            className="noise_slide_3__cls-1"
            cx={1028.56}
            cy={538.53}
            rx={6.6}
            ry={6.59}
          />
        </clipPath>
        <clipPath id="noise_slide_3__clip-path-3">
          <ellipse
            className="noise_slide_3__cls-1"
            cx={375.72}
            cy={349.55}
            rx={6.82}
            ry={6.81}
          />
        </clipPath>
        <clipPath id="noise_slide_3__clip-path-4">
          <path
            className="noise_slide_3__cls-1"
            d="M817.15 262.83l-21.29-4.56-3.74-.8-59.9-12.85 3.59-16.62a9.72 9.72 0 00-7.47-11.54l-2.81-.6a2.72 2.72 0 00-3.22 2.08l-5.06 23.5-1.87-.4a11.45 11.45 0 008.81 13.61l57.35 20.12 2 22.33a7.26 7.26 0 005.58 8.61l36.89 7.91a12.45 12.45 0 0014.78-9.53l.75-3.5a31.76 31.76 0 00-24.39-37.76z"
          />
        </clipPath>
        <clipPath id="noise_slide_3__clip-path-5">
          <ellipse
            className="noise_slide_3__cls-1"
            cx={1133.52}
            cy={198.86}
            rx={5.27}
            ry={5.62}
          />
        </clipPath>
        <linearGradient
          id="noise_slide_3__linear-gradient"
          x1={835.85}
          y1={253.16}
          x2={828.32}
          y2={289.61}
          gradientTransform="rotate(-10.93 863.295 265.76)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#80cfd3" />
          <stop offset={1} stopColor="#c0ffff" />
        </linearGradient>
        <linearGradient
          id="noise_slide_3__linear-gradient-2"
          x1={802}
          y1={254.02}
          x2={794.47}
          y2={290.45}
          gradientTransform="rotate(-10.93 826.216 264.69)"
          xlinkHref="#noise_slide_3__linear-gradient"
        />
        <style>
          {
            '.noise_slide_3__cls-1{fill:none}.noise_slide_3__cls-2{fill:#efb45e}.noise_slide_3__cls-3{fill:#ffe26e}.noise_slide_3__cls-5{fill:#6bb8ed}.noise_slide_3__cls-6{fill:#3e7cac}.noise_slide_3__cls-8{fill:#fff}.noise_slide_3__cls-9{fill:#414042}.noise_slide_3__cls-10{fill:#af4d94}.noise_slide_3__cls-11{fill:#d9498f}.noise_slide_3__cls-13{fill:#f7ca8f}.noise_slide_3__cls-14{fill:#7763b8}.noise_slide_3__cls-16{fill:#4d81ba}.noise_slide_3__cls-20{fill:#7dd3f4}'
          }
        </style>
      </defs>
      <g id="noise_slide_3__bird">
        <path
          className="noise_slide_3__cls-2"
          d="M244.22 183.19l16.27 6.19a3.44 3.44 0 004.42-2.57 4.18 4.18 0 00-2.21-5.1l-16.26-6.18a3.43 3.43 0 00-4.44 2.57 4.16 4.16 0 002.22 5.09z"
        />
        <path
          className="noise_slide_3__cls-3"
          d="M247.05 182.67l14.85-10a4.41 4.41 0 001.29-5.52 3.25 3.25 0 00-4.78-1.45l-14.85 10a4.4 4.4 0 00-1.29 5.52 3.25 3.25 0 004.78 1.45z"
        />
        <path
          className="wing"
          d="M231.69 194.08h20.7a3.16 3.16 0 013.16 3.16v1.31a19.67 19.67 0 01-19.67 19.67h-4.19a12.07 12.07 0 01-12.07-12.07 12.07 12.07 0 0112.07-12.07z"
          transform="rotate(6.28 237.667 206.274)"
          fill="#366a9e"
        />
        <path
          className="noise_slide_3__cls-5"
          d="M211.1 191.5h36.32v15.2a15.12 15.12 0 01-15.12 15.12h-41.5a2.19 2.19 0 01-2.19-2.19V214a22.48 22.48 0 0122.49-22.5zM233 164.38a14.41 14.41 0 0114.41 14.41v12.7H218.6v-12.7a14.41 14.41 0 0114.4-14.41z"
        />
        <motion.path
          animate={{
            rotate: [0, -30, 0, -30, 0, -30, 0, -30, 0],
          }}
          transition={{
            duration: 2, repeat: Infinity, repeatDelay: 2,
          }}
          style={{
            transformBox: 'fill-box',
            originX: 'left',
            originY: 'center',
          }}
          className="noise_slide_3__cls-6 wing"
          d="M232.5 197.68h17.14a3.09 3.09 0 013.09 3.09v1.78a19.27 19.27 0 01-19.27 19.27h-1A11.82 11.82 0 01220.68 210v-.49a11.82 11.82 0 0111.82-11.83z"
        />
        <g
          clipPath="url(#noise_slide_3__clip-path)"
          id="noise_slide_3__eye_grp"
          data-name="eye grp"
        >
          <ellipse
            className="noise_slide_3__cls-8"
            cx={233.46}
            cy={176.37}
            rx={7.09}
            ry={6.81}
            transform="rotate(-73.57 233.466 176.374)"
            id="noise_slide_3__eye_shape"
            data-name="eye shape"
          />
          <ellipse
            id="noise_slide_3__iris"
            className="noise_slide_3__cls-9"
            cx={236.82}
            cy={176.95}
            rx={5.98}
            ry={5.74}
            transform="rotate(-73.57 236.818 176.951)"
          />
        </g>
        <path
          className="noise_slide_3__cls-6"
          d="M218.02 221.82h3.28v12.54h-3.28zM224.15 221.82h3.28v12.54h-3.28z"
        />
      </g>
      <g id="noise_slide_3__bird_in_the_nest" data-name="bird in the nest">
        <g id="noise_slide_3__bird-2" data-name="bird">
          <motion.path
            animate={{
              rotate: [0, 30, 0, 30, 0, 30, 0, 30, 0],
            }}
            transition={{
              duration: 2, repeat: Infinity, repeatDelay: 6,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'right',
              originY: 'center',
            }}
            className="noise_slide_3__cls-10 wing"
            d="M1041.9 566.52l-30.61-14.72a1.65 1.65 0 00-2.21.77l-.25.52a23.57 23.57 0 0011 31.51l7.84 3.77a11.77 11.77 0 0015.69-5.47l2.46-5.1a8.44 8.44 0 00-3.92-11.28z"
          />
          <rect
            className="noise_slide_3__cls-11"
            x={1017.13}
            y={527.28}
            width={24.68}
            height={24.63}
            rx={12.32}
            transform="rotate(12.65 1029.369 539.525)"
          />
          <g
            clipPath="url(#noise_slide_3__clip-path-2)"
            id="noise_slide_3__eye_grp-2"
            data-name="eye grp"
          >
            <ellipse
              className="noise_slide_3__cls-8"
              cx={1028.56}
              cy={538.53}
              rx={6.6}
              ry={6.59}
              id="noise_slide_3__eye_shape-2"
              data-name="eye shape"
            />
            <ellipse
              id="noise_slide_3__iris-2"
              data-name="iris"
              className="noise_slide_3__cls-9"
              cx={1025.37}
              cy={537.66}
              rx={5.57}
              ry={5.55}
            />
          </g>
          <path
            className="noise_slide_3__cls-11"
            d="M1033.35 561.17h12.83a28.37 28.37 0 0128.37 28.37h-46.68v-22.89a5.48 5.48 0 015.48-5.48z"
          />
          <motion.path
            animate={{
              rotate: [0, -30, 0, -30, 0, -30, 0, -30, 0],
            }}
            transition={{
              duration: 2, repeat: Infinity, repeatDelay: 6,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'left',
              originY: 'center',
            }}
            className="noise_slide_3__cls-10 wing"
            d="M1044.93 562.47l32.07-16.64a1.83 1.83 0 012.47.77 24 24 0 01-10.26 32.4l-9.34 4.85a12.1 12.1 0 01-16.29-5.13l-2.36-4.52a8.68 8.68 0 013.71-11.73z"
          />
          <path
            className="noise_slide_3__cls-11"
            transform="matrix(.97 -.23 .23 .97 -99.76 249.43)"
            d="M1031.57 547.73h4.5v20.86h-4.5z"
          />
          <path
            className="noise_slide_3__cls-3"
            d="M1017 538.52l-9.68-5.84a2.3 2.3 0 01-.78-3.16 2.31 2.31 0 013.17-.78l9.68 5.84a2.3 2.3 0 01.78 3.16 2.32 2.32 0 01-3.17.78z"
          />
          <path
            className="noise_slide_3__cls-3"
            d="M1018.89 538.84l-10.8 3.37a2.3 2.3 0 01-2.88-1.52 2.29 2.29 0 011.51-2.88l10.8-3.36a2.3 2.3 0 012.88 1.51 2.29 2.29 0 01-1.51 2.88z"
          />
        </g>
        <g id="noise_slide_3__nest">
          <path
            className="noise_slide_3__cls-2"
            d="M1069.41 603.74H1019c-7.68 0-13.9-5.8-13.9-13 0-7.15 6.22-12.95 13.9-12.95 0 0 14.77 4.57 26.9 2.55a226.14 226.14 0 0123.47-2.55c7.67 0 13.89 5.8 13.89 12.95.04 7.2-6.18 13-13.85 13z"
          />
          <path
            className="noise_slide_3__cls-13"
            d="M1079.1 579.78c-5.7 3.18-11.78 5.71-17.72 8.41-7.37 3.35-14.77 6.66-22 10.35a73 73 0 00-8.72 5c-1.41 1-.06 3.35 1.37 2.34 5.53-3.87 11.92-6.65 18-9.51 7.28-3.41 14.63-6.66 21.92-10 2.86-1.33 5.75-2.65 8.51-4.19 1.52-.85.16-3.2-1.37-2.35zM1055.44 576.53a111.51 111.51 0 00-24.47 8.91 139 139 0 00-15.9 10.32c-1.88 1.35-3.76 2.7-5.62 4.08-1.39 1 0 3.38 1.36 2.34q6-4.42 12.14-8.66a89.36 89.36 0 0119-10 133 133 0 0114.25-4.34 1.36 1.36 0 00-.72-2.62z"
          />
          <path
            className="noise_slide_3__cls-13"
            d="M1007 583.29c2.94 1.68 6 3.1 9.1 4.55 4.06 1.92 8.14 3.79 12.28 5.54a89.28 89.28 0 0011.74 3.91c6.28 1.66 12.58 3.21 18.88 4.75 4.21 1 8.68 1.8 12.72 3.39 1.62.64 2.33-2 .72-2.61a81.8 81.8 0 00-10.58-2.87c-6-1.49-12.09-3-18.11-4.51a99.54 99.54 0 01-12.9-4c-4.06-1.66-8-3.49-12-5.35-3.51-1.65-7.09-3.27-10.46-5.19a1.36 1.36 0 00-1.37 2.34zM1083.05 596.44c-1.41-1.59-3.8-2.51-5.68-3.38-3.31-1.52-6.72-2.87-10.12-4.19-7.36-2.85-14.82-5.46-22.17-8.32-1.34-.53-2.63-1-3.84-1.58-.42-.19-.84-.38-1.25-.6l-.45-.25c-.38-.22 0 .06-.07 0-1.26-1.2-3.18.71-1.91 1.92s3.54 1.87 5.21 2.54c3.19 1.28 6.42 2.47 9.64 3.66 7.46 2.76 15 5.41 22.29 8.63 1.44.64 2.89 1.3 4.27 2.06a15.67 15.67 0 011.42.85 2.14 2.14 0 01.75.63c1.16 1.31 3.07-.62 1.91-1.92z"
          />
        </g>
      </g>
      <g id="noise_slide_3__bird-3" data-name="bird">
        <path
          className="noise_slide_3__cls-14"
          d="M338.5 431.18h2.99v22.19h-2.99zM345.35 431.18h2.99v22.07h-2.99z"
        />
        <motion.path
          animate={{
            rotate: [0, -30, 0, -30, 0, -30, 0, -30, 0],
          }}
          transition={{
            delay: 1, duration: 2, repeat: Infinity, repeatDelay: 6,
          }}
          style={{
            transformBox: 'fill-box',
            originX: 'left',
            originY: 'center',
          }}
          className="noise_slide_3__cls-10"
          d="M356.88 406.61l38.34-18.44a1.75 1.75 0 012.34.81 24.92 24.92 0 01-11.66 33.31l-14.28 6.87a12.44 12.44 0 01-16.62-5.78l-2.34-4.84a8.94 8.94 0 014.22-11.93z"
        />
        <path
          className="noise_slide_3__cls-10"
          transform="rotate(-166.87 368.026 385.372)"
          d="M365.7 362.77h4.65v45.21h-4.65z"
        />
        <path
          className="noise_slide_3__cls-2"
          d="M388.74 358.76l12.1 7.11a2.87 2.87 0 003.92-1 2.86 2.86 0 00-1-3.91l-12.1-7.11a2.87 2.87 0 00-3.92 1 2.85 2.85 0 001 3.91z"
        />
        <path
          className="noise_slide_3__cls-3"
          d="M391.07 358.89l13.36-4.33a2.86 2.86 0 001.84-3.6 2.87 2.87 0 00-3.61-1.83l-13.35 4.33a2.85 2.85 0 00-1.84 3.6 2.85 2.85 0 003.6 1.83z"
        />
        <rect
          className="noise_slide_3__cls-11"
          x={361.13}
          y={336.22}
          width={30.64}
          height={30.58}
          rx={15.29}
          transform="rotate(167.35 376.454 351.517)"
        />
        <g
          clipPath="url(#noise_slide_3__clip-path-3)"
          id="noise_slide_3__eye_grp-3"
          data-name="eye grp"
        >
          <ellipse
            className="noise_slide_3__cls-8"
            cx={375.72}
            cy={349.55}
            rx={6.82}
            ry={6.81}
            id="noise_slide_3__eye_shape-3"
            data-name="eye shape"
          />
          <ellipse
            id="noise_slide_3__iris-3"
            data-name="iris"
            className="noise_slide_3__cls-9"
            cx={379}
            cy={350.51}
            rx={5.75}
            ry={5.74}
          />
        </g>
        <path
          className="noise_slide_3__cls-11"
          d="M373.66 418.86a12.21 12.21 0 01-10.06 14.05l-20 3.09-35.28 4.78c-3-18.73 24.31-37.7 42.91-40.69l12.43-2a6.62 6.62 0 017.56 5.51z"
        />
        <path
          className="noise_slide_3__cls-10"
          d="M356.58 423.31c.92 5.71-2.4 11-7.42 11.79l-41.51 6.68a.18.18 0 01-.22-.18c-2.37-15.9 17.84-32.31 31.83-34.57l9.4-1.51c2.71-.43 5.32 1.71 5.81 4.8z"
        />
      </g>
      <g id="noise_slide_3__FLOAT">
        <g id="noise_slide_3__helicopter">
          <rect
            className="noise_slide_3__cls-16"
            x={771.8}
            y={317.86}
            width={63.17}
            height={1.91}
            rx={0.91}
            transform="rotate(12.11 803.096 318.646)"
          />
          <path
            className="noise_slide_3__cls-16"
            d="M790.26 305.25l1.87.4-5.42 10.56-1.88-.4 5.43-10.56zM820.06 311.64l-2.3-.49 1.28 12 2.29.49-1.27-12z"
          />
          <rect
            className="noise_slide_3__cls-16"
            x={801.47}
            y={242.48}
            width={3.83}
            height={16.24}
            rx={1.81}
            transform="rotate(12.11 803.111 250.48)"
          />
          <rect
            className="noise_slide_3__cls-5"
            x={786.38}
            y={251.65}
            width={28.71}
            height={13.37}
            rx={6.35}
            transform="rotate(12.11 800.45 258.199)"
          />
          <path
            className="noise_slide_3__cls-5"
            d="M817.15 262.83l-21.29-4.56-3.74-.8-59.9-12.85 3.59-16.62a9.72 9.72 0 00-7.47-11.54l-2.81-.6-1.87-.4a2.7 2.7 0 00-3.22 2.07L715.38 241v.31a11.47 11.47 0 008.87 13.3l57.35 20.12 2 22.33a7.26 7.26 0 005.58 8.61l36.89 7.91a12.45 12.45 0 0014.78-9.53l.75-3.5a31.76 31.76 0 00-24.45-37.72z"
          />
          <g clipPath="url(#noise_slide_3__clip-path-4)">
            <path
              d="M818.4 276.38h35.25v22.93h-23.38a16.82 16.82 0 01-16.82-16.82v-1.15a5 5 0 014.95-4.96z"
              transform="rotate(12.11 833.229 287.707)"
              fill="url(#noise_slide_3__linear-gradient)"
            />
            <rect
              x={790.96}
              y={270.28}
              width={18.19}
              height={32.48}
              rx={7.6}
              transform="rotate(11.5 796.712 294.98)"
              fill="url(#noise_slide_3__linear-gradient-2)"
            />
          </g>
          <ellipse
            className="noise_slide_3__cls-20"
            cx={718.85}
            cy={247.61}
            rx={15.32}
            ry={15.28}
          />
          <motion.g
            animate={{
              scale: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 0.1, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
          >
            <ellipse
              id="noise_slide_3__propeller"
              className="noise_slide_3__cls-20"
              cx={807}
              cy={240.63}
              rx={9.07}
              ry={60.78}
              transform="rotate(-77.9 806.96 240.653)"
            />
          </motion.g>
          <g id="noise_slide_3__noise_lines_helicopter">
            <motion.path
              animate={{
                opacity: [0, 1, 0],
                scale: [0.5, 1],
                x: [0, 20],
                y: [0, -10],
              }}
              transition={{
                duration: 3, repeat: Infinity,
              }}
              className="noise_slide_3__cls-3"
              d="M899.51 249.06a28.29 28.29 0 00-23-32.77 2.4 2.4 0 00-2.88 1.63 2.35 2.35 0 001.63 2.87 23.65 23.65 0 0119.75 27 2.41 2.41 0 001.63 2.87 2.35 2.35 0 002.88-1.63z"
            />
            <motion.path
              animate={{
                opacity: [0, 1, 0],
                scale: [0.5, 1],
                x: [0, 20],
                y: [0, -10],
              }}
              transition={{
                delay: 2, duration: 3, repeat: Infinity,
              }}
              className="noise_slide_3__cls-3"
              d="M899.51 249.06a28.29 28.29 0 00-23-32.77 2.4 2.4 0 00-2.88 1.63 2.35 2.35 0 001.63 2.87 23.65 23.65 0 0119.75 27 2.41 2.41 0 001.63 2.87 2.35 2.35 0 002.88-1.63z"
            />
          </g>
        </g>
        <g id="noise_slide_3__bird_3">
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 0.5, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'bottom',
            }}
            d="M1124.46 228.71l18.32 3.84c1.83.38 2.6-1 1.73-3.16l-.79-1.92c-6.59-16-23.07-31.31-36.82-34.19h-.05l12.71 30.89c.87 2.12 3.07 4.16 4.9 4.54z"
            stroke="#4d81ba"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3.26}
            fill="#4d81ba"
            className="wing"
          />
          <path
            className="noise_slide_3__cls-5"
            d="M1134 183c7.65 2 13.46 10.1 13 18.06l-1.44 24.28-27.21-6.87 1.1-24.49c.37-7.98 6.9-12.98 14.55-10.98z"
          />
          <path
            className="noise_slide_3__cls-5"
            d="M1154.14 248.51l-12.67-1.59c-12.72-1.59-23.83-12.33-23.29-24.29l.28-6.24c0-.79.85-1.22 1.82-1l58.07 14.69a1.12 1.12 0 01.79 1.25c-1.88 11.2-12.41 18.76-25 17.18z"
          />
          <motion.path
            animate={{
              scaleY: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 0.5, repeat: Infinity,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'bottom',
            }}
            className="noise_slide_3__cls-16"
            d="M1139.4 231.84l20.66 4.33c2.06.43 5-1 6.67-3.31l1.46-2c12.2-17 9.51-33.38-6-36.63h-.06L1138.6 227c-1.6 2.23-1.27 4.41.8 4.84z"
          />
          <g
            clipPath="url(#noise_slide_3__clip-path-5)"
            id="noise_slide_3__eye_grp-4"
            data-name="eye grp"
          >
            <ellipse
              className="noise_slide_3__cls-8"
              cx={1133.52}
              cy={198.86}
              rx={5.27}
              ry={5.62}
              id="noise_slide_3__eye_shape-4"
              data-name="eye shape"
            />
            <ellipse
              id="noise_slide_3__iris-4"
              data-name="iris"
              className="noise_slide_3__cls-9"
              cx={1130.95}
              cy={198.2}
              rx={4.45}
              ry={4.74}
            />
          </g>
          <path
            className="noise_slide_3__cls-2"
            d="M1122.48 203.46l-12.84 4.38c-1.44.5-2.92-.54-3.31-2.32a3.52 3.52 0 011.92-4.11l12.84-4.38c1.44-.5 2.93.54 3.31 2.32a3.52 3.52 0 01-1.92 4.11z"
          />
          <path
            className="noise_slide_3__cls-3"
            d="M1120.32 202.9l-11.08-8.9a3.84 3.84 0 01-.76-4.62 2.44 2.44 0 013.76-1l11.07 8.9a3.8 3.8 0 01.76 4.61 2.43 2.43 0 01-3.75 1.01z"
          />
          <g id="noise_slide_3__sound_bird_3">
            <motion.g
              id="noise_slide_3__heart"
              animate={{
                x: [0, -40],
                opacity: [0, 1, 0],
              }}
              transition={{
                duration: 2, repeat: Infinity,
              }}
            >
              <path
                className="noise_slide_3__cls-3"
                d="M1081.76 197.32l4.31 8.34a2.05 2.05 0 002.89.89l12.27-7.2a5.68 5.68 0 002-7.45l-.37-.72a5 5 0 00-7-2.16z"
              />
              <path
                className="noise_slide_3__cls-3"
                d="M1092.81 204.26l3.94-2.32-7.92-15.31a5 5 0 00-7-2.17l-.62.36a5.71 5.71 0 00-2 7.5l4.75 9.19a6.37 6.37 0 008.85 2.75z"
              />
            </motion.g>
            <motion.path
              animate={{
                x: [0, -40],
                opacity: [0, 1, 0],
              }}
              transition={{
                delay: 1, duration: 2, repeat: Infinity,
              }}
              className="noise_slide_3__cls-3"
              d="M1098.49 190.75l-14.62-4.42a.92.92 0 00-1.15.66l-3.65 12.86a3.14 3.14 0 00-2.17-.88 3.49 3.49 0 000 7 3.35 3.35 0 003.24-3l4.11-14.44 12.85 3.9-2.78 11.46a3.16 3.16 0 00-2.09-.8 3.49 3.49 0 000 7 3.33 3.33 0 003.21-2.85l3.7-15.23a1 1 0 00-.65-1.26z"
            />
            <g id="noise_slide_3__sound_bird_3-2" data-name="sound_bird_3">
              <motion.g
                id="noise_slide_3__heart-2"
                data-name="heart"
                animate={{
                  x: [0, -40],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  delay: 0.5, duration: 2, repeat: Infinity,
                }}
              >
                <path
                  className="noise_slide_3__cls-3"
                  d="M983.09 528.74l4.31 8.35a2.07 2.07 0 002.89.89l12.27-7.21a5.68 5.68 0 002-7.45l-.38-.72a5 5 0 00-7-2.16z"
                />
                <path
                  className="noise_slide_3__cls-3"
                  d="M994.13 535.68l3.94-2.31-7.91-15.32a5 5 0 00-7-2.17l-.62.37a5.71 5.71 0 00-2 7.49l4.75 9.19a6.35 6.35 0 008.84 2.75z"
                />
              </motion.g>
              <motion.path
                animate={{
                  x: [0, -30],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  delay: 1.5, duration: 2, repeat: Infinity,
                }}
                data-name="heart"
                className="noise_slide_3__cls-3"
                d="M999.82 522.18l-14.62-4.43a.93.93 0 00-1.15.67l-3.66 12.85a3.14 3.14 0 00-2.16-.88 3.5 3.5 0 103.24 4l4.1-14.44 12.86 3.9-2.79 11.47a3.08 3.08 0 00-2.08-.81 3.49 3.49 0 000 7 3.32 3.32 0 003.21-2.84l3.7-15.23a1 1 0 00-.65-1.26z"
              />
            </g>
            <g id="noise_slide_3__sound_bird_3-3" data-name="sound_bird_3">
              <motion.g
                animate={{
                  x: [0, 80],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  duration: 2, repeat: Infinity,
                }}
                id="noise_slide_3__heart-3"
                data-name="heart"
              >
                <path
                  className="noise_slide_3__cls-3"
                  d="M416.66 356.07l4.31 8.35a2.07 2.07 0 002.89.89l12.27-7.21a5.68 5.68 0 002-7.45l-.37-.72a5 5 0 00-7-2.16z"
                />
                <path
                  className="noise_slide_3__cls-3"
                  d="M427.71 363l3.94-2.31-7.92-15.32a5 5 0 00-7-2.17l-.62.37a5.7 5.7 0 00-2 7.49l4.75 9.19a6.37 6.37 0 008.85 2.75z"
                />
              </motion.g>
              <motion.path
                animate={{
                  x: [0, 90],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  delay: 1, duration: 2, repeat: Infinity,
                }}
                className="noise_slide_3__cls-3"
                d="M433.39 349.51l-14.62-4.43a.93.93 0 00-1.15.67L414 358.6a3.16 3.16 0 00-2.16-.88 3.49 3.49 0 103.23 4l4.11-14.44 12.85 3.9-2.78 11.46a3.16 3.16 0 00-2.09-.8 3.49 3.49 0 000 7 3.32 3.32 0 003.21-2.84l3.7-15.23a1 1 0 00-.68-1.26z"
              />
            </g>
            <g id="noise_slide_3__sound_bird_3-4" data-name="sound_bird_3">
              <motion.g
                animate={{
                  x: [0, 90],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  delay: 1, duration: 3, repeat: Infinity,
                }}
                id="noise_slide_3__heart-4"
                data-name="heart"
              >
                <path
                  className="noise_slide_3__cls-3"
                  d="M276 173.6l4.32 8.35a2.06 2.06 0 002.88.89l12.28-7.21a5.68 5.68 0 002-7.45l-.37-.72a5 5 0 00-7-2.16z"
                />
                <path
                  className="noise_slide_3__cls-3"
                  d="M287 180.54l3.94-2.31-7.94-15.32a5 5 0 00-7-2.17l-.62.37a5.7 5.7 0 00-2 7.49l4.75 9.19a6.36 6.36 0 008.87 2.75z"
                />
              </motion.g>
              <motion.path
                animate={{
                  x: [0, 90],
                  opacity: [0, 1, 0],
                }}
                transition={{
                  delay: 2, duration: 3, repeat: Infinity,
                }}
                className="noise_slide_3__cls-3"
                d="M292.7 167l-14.62-4.43a.92.92 0 00-1.15.67l-3.66 12.85a3.15 3.15 0 00-2.16-.87 3.49 3.49 0 000 7 3.34 3.34 0 003.23-3l4.11-14.44 12.86 3.9-2.79 11.47a3.1 3.1 0 00-2.09-.81 3.49 3.49 0 000 7 3.32 3.32 0 003.21-2.84l3.71-15.23a1 1 0 00-.65-1.27z"
              />
            </g>
          </g>
        </g>
        <g id="noise_slide_3__noise_lines_car_1">
          <motion.path
            animate={{
              opacity: [0, 1, 0],
              scale: [0, 1.5],
              x: [0, -20],
              y: [0, -20],
            }}
            transition={{
              duration: 3, repeat: Infinity,
            }}
            className="noise_slide_3__cls-3"
            d="M574.42 588.41a15.92 15.92 0 00-13.17 18.3 2.36 2.36 0 002.88 1.63 2.4 2.4 0 001.63-2.88c.07.5 0-.13 0-.24v-.64-1.28-.62c.05-.57-.07.3 0-.21s.15-.81.26-1.21a11.37 11.37 0 01.37-1.17c0-.15.23-.58.07-.2.08-.19.16-.38.25-.56a14.08 14.08 0 011.33-2.16c.1-.13.08-.11-.06.07.07-.08.13-.16.2-.23s.26-.31.4-.46.57-.59.87-.86l.46-.4.24-.19q-.27.2-.06.06.5-.36 1-.69c.36-.21.72-.4 1.1-.58.06 0 .63-.28.39-.18l.41-.15a13.53 13.53 0 012.6-.64 2.35 2.35 0 001.63-2.88 2.39 2.39 0 00-2.87-1.63z"
          />
          <motion.path
            animate={{
              opacity: [0, 1, 0],
              scale: [0, 1.5],
              x: [0, -20],
              y: [0, -20],
            }}
            transition={{
              delay: 1, duration: 3, repeat: Infinity,
            }}
            className="noise_slide_3__cls-3"
            d="M574.42 588.41a15.92 15.92 0 00-13.17 18.3 2.36 2.36 0 002.88 1.63 2.4 2.4 0 001.63-2.88c.07.5 0-.13 0-.24v-.64-1.28-.62c.05-.57-.07.3 0-.21s.15-.81.26-1.21a11.37 11.37 0 01.37-1.17c0-.15.23-.58.07-.2.08-.19.16-.38.25-.56a14.08 14.08 0 011.33-2.16c.1-.13.08-.11-.06.07.07-.08.13-.16.2-.23s.26-.31.4-.46.57-.59.87-.86l.46-.4.24-.19q-.27.2-.06.06.5-.36 1-.69c.36-.21.72-.4 1.1-.58.06 0 .63-.28.39-.18l.41-.15a13.53 13.53 0 012.6-.64 2.35 2.35 0 001.63-2.88 2.39 2.39 0 00-2.87-1.63z"
          />

        </g>
        <g id="noise_slide_3__noise_lines_car_2">
          <motion.path
            animate={{
              opacity: [0, 1, 0],
              scale: [0, 1.5],
              x: [0, 20],
              y: [0, -10],
            }}
            transition={{
              delay: 1, duration: 3, repeat: Infinity,
            }}
            className="noise_slide_3__cls-3"
            d="M911.69 666.76a15.91 15.91 0 00-10.29-20.06 2.34 2.34 0 10-1.25 4.5 11.37 11.37 0 016.51 5.73c.09.17.17.34.25.52s-.07-.21.07.17.28.79.39 1.19.21.81.29 1.22c0 .05.1.64.06.37s0 .32 0 .38a14.48 14.48 0 010 2.55v.2c0 .13-.07.39-.11.59-.1.47-.24.94-.38 1.4a2.33 2.33 0 104.5 1.24z"
          />
          <motion.path
            animate={{
              opacity: [0, 1, 0],
              scale: [0, 1.5],
              x: [0, 20],
              y: [0, -10],
            }}
            transition={{
              delay: 2, duration: 3, repeat: Infinity,
            }}
            className="noise_slide_3__cls-3"
            d="M911.69 666.76a15.91 15.91 0 00-10.29-20.06 2.34 2.34 0 10-1.25 4.5 11.37 11.37 0 016.51 5.73c.09.17.17.34.25.52s-.07-.21.07.17.28.79.39 1.19.21.81.29 1.22c0 .05.1.64.06.37s0 .32 0 .38a14.48 14.48 0 010 2.55v.2c0 .13-.07.39-.11.59-.1.47-.24.94-.38 1.4a2.33 2.33 0 104.5 1.24z"
          />
        </g>
      </g>
    </svg>
  );
}

export default NoiseAnimationSlide3;
