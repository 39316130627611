import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import {
  Container, Row,
} from 'react-bootstrap';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import './wp-noise-post.scss';
import { GlobalDispatchContext } from '../../context/GlobalContextProvider';
import SummaryContent from '../../components/Chapters/SummaryContent/SummaryContent';
import ChapterCarouselContent from '../../components/Chapters/ChapterCarouselContent/ChapterCarouselContent';
import { ThemeDispatchContext } from '../../context/ThemeContextProvider';

import Eboti from './components/Eboti/Eboti';

import LastSlideImage from './components/ImgComponents/LastSlideImage';

import NoiseAnimationSlide1 from './components/NoiseAnimationSlide1/NoiseAnimationSlide1';
import NoiseAnimationSlide2 from './components/NoiseAnimationSlide2/NoiseAnimationSlide2';
import NoiseAnimationSlide3 from './components/NoiseAnimationSlide3/NoiseAnimationSlide3';
import NoiseAnimationSlide4 from './components/NoiseAnimationSlide4/NoiseAnimationSlide4';
import NoiseAnimationSlide5 from './components/NoiseAnimationSlide5/NoiseAnimationSlide5';
import NoiseAnimationSlide6 from './components/NoiseAnimationSlide6/NoiseAnimationSlide6';

import NoiseNoAnimationSlide1 from './components/NoiseAnimationSlide1/NoiseNoAnimationSlide1';
import NoiseNoAnimationSlide2 from './components/NoiseAnimationSlide2/NoiseNoAnimationSlide2';
import NoiseNoAnimationSlide3 from './components/NoiseAnimationSlide3/NoiseNoAnimationSlide3';
import NoiseNoAnimationSlide4 from './components/NoiseAnimationSlide4/NoiseNoAnimationSlide4';
import NoiseNoAnimationSlide5 from './components/NoiseAnimationSlide5/NoiseNoAnimationSlide5';
import NoiseNoAnimationSlide6 from './components/NoiseAnimationSlide6/NoiseNoAnimationSlide6';

import BgSlide1 from './components/ImgComponents/NoiseBgSlide1';
import BgSlide2 from './components/ImgComponents/NoiseBgSlide2';
import BgSlide3 from './components/ImgComponents/NoiseBgSlide3';
import BgSlide4 from './components/ImgComponents/NoiseBgSlide4';
import BgSlide5 from './components/ImgComponents/NoiseBgSlide5';
import BgSlide6 from './components/ImgComponents/NoiseBgSlide6';

const wpNoisePost = ({
  data: {
    wpNoisePost: {
      title, acfChaptersFields, seo, language, translations,
    },
  },
}) => {
  useEffect(() => {
    dispatch({ type: 'SET_TRANSLATION_LINKS', payload: { translationLinks: translations } });
    dispatch({ type: 'SET_LANG', payload: { lang: language } });
    dispatchTheme({ type: 'SET_THEME', payload: { themeName: 'noise' } });
  }, []);

  const dispatch = useContext(GlobalDispatchContext);
  const dispatchTheme = useContext(ThemeDispatchContext);

  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselOrSummary, setCarouselOrSummary] = useState('carousel');

  const slideAnimations = [<NoiseAnimationSlide1 />,
    <NoiseAnimationSlide2 />, <NoiseAnimationSlide3 />, <NoiseAnimationSlide4 />,
    <NoiseAnimationSlide5 />, <NoiseAnimationSlide6 />];

  const slideNoAnimations = [<NoiseNoAnimationSlide1 />,
    <NoiseNoAnimationSlide2 />, <NoiseNoAnimationSlide3 />, <NoiseNoAnimationSlide4 />,
    <NoiseNoAnimationSlide5 />, <NoiseNoAnimationSlide6 />];

  const slideBg = [<BgSlide1 />, <BgSlide2 />, <BgSlide3 />,
    <BgSlide4 />, <BgSlide5 />, <BgSlide6 />];

  const handleCarouselOrSummary = () => {
    if (carouselOrSummary === 'carousel') {
      setCarouselOrSummary('summary');
    } else {
      setCarouselOrSummary('carousel');
    }
  };

  return (
    <Layout>
      <SEO
        title={title}
        meta={
        [
          { opengraphSiteName: seo.opengraphSiteName },
          { opengraphType: seo.opengraphType },
          { opengraphTitle: seo.opengraphTitle },
          { opengraphImage: seo.opengraphImage?.link },
          { imageHeight: seo.opengraphImage?.mediaDetails.height },
          { imageWidth: seo.opengraphImage?.mediaDetails.width },
        ]
    }
      />
      <Container fluid className="container-global-styling noise-post-container custom-max-height">
        <Row className="h-100 justify-content-center align-items-center">

          {
            carouselOrSummary === 'carousel'
              ? (
                <ChapterCarouselContent
                  chapterTitle={title}
                  acfChaptersFields={acfChaptersFields}
                  handleCarouselOrSummary={handleCarouselOrSummary}
                  eboti={<Eboti index={activeIndex} />}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  slideAnimations={slideAnimations}
                  slideNoAnimations={slideNoAnimations}
                  slideBg={slideBg}
                />
              )

              : (
                <SummaryContent
                  summaryTitle={acfChaptersFields.summaryTitle}
                  summaryText={acfChaptersFields.summaryText}
                  exercisesLinkLabelText={acfChaptersFields.exercisesLinkLabelText ? acfChaptersFields.exercisesLinkLabelText : ''}
                  exercisesPageLink={acfChaptersFields.exercisesPageLink ? acfChaptersFields.exercisesPageLink.uri : ''}
                  handleCarouselOrSummary={handleCarouselOrSummary}
                  lastSlideImage={<LastSlideImage />}
                />
              )
              }
        </Row>
      </Container>
    </Layout>
  );
};
export default wpNoisePost;

export const query = graphql`
  query($id: String) {
    wpNoisePost(id: { eq: $id }) {
      id  
      title
      content
      translations {
        language {
          locale
          name
        }
        uri
      }
      language {
          locale
          name
      }
      acfChaptersFields {
        fieldGroupName
        readMoreButtonLabel
        slidesContent {
        fieldGroupName
        text
        title
        introductionText
        additionalFieldsContent {
            title
            text
          }
        }
        summaryTitle
        summaryText
        exercisesLinkLabelText
        exercisesPageLink {
          ... on WpNoiseExercisesPost {
            uri
          }
        }
      }
      seo {
          opengraphUrl
          opengraphSiteName
          opengraphType
          opengraphTitle
          opengraphImage {
            link
            mediaDetails {
              height
              width
            }
          }
         }
    }
  }
`;
