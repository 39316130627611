import * as React from 'react';

function NoiseNoAnimationSlide1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="noise_slide_1_clip-path">
          <path
            className="noise_slide_1_cls-2"
            d="M636.04 286.21h18.61v18.61h-18.61z"
          />
        </clipPath>
        <clipPath id="noise_slide_1_clip-path-2">
          <path
            className="noise_slide_1_cls-1"
            d="M438.07 446.58c5.37 0 11.61-.72 15.74-3.52 6.89-4.65 10.19-13.42 10.19-22.36v-22.78A25.89 25.89 0 00438.07 372a25.88 25.88 0 00-25.88 25.88v22.82a25.88 25.88 0 0025.88 25.88z"
          />
        </clipPath>
        <clipPath id="noise_slide_1_clip-path-3">
          <ellipse
            className="noise_slide_1_cls-2"
            cx={448.22}
            cy={403.1}
            rx={7.25}
            ry={10.15}
          />
        </clipPath>
        <clipPath id="noise_slide_1_clip-path-4">
          <path
            className="noise_slide_1_cls-2"
            d="M529 476.4c5.36 0 25.89-4.35 25.89-25.88v-22.78A25.89 25.89 0 00529 401.86a25.88 25.88 0 00-25.88 25.88v22.78c-.02 14.29 6.4 25.88 25.88 25.88z"
          />
        </clipPath>
        <clipPath id="noise_slide_1_clip-path-7">
          <path
            className="noise_slide_1_cls-2"
            d="M638 295.51a7.37 7.37 0 107.36-7.36 7.36 7.36 0 00-7.36 7.36"
          />
        </clipPath>
        <clipPath id="noise_slide_1_clip-path-8">
          <circle className="noise_slide_1_cls-2" cx={986.47} cy={125.17} r={27.14} />
        </clipPath>
        <clipPath id="noise_slide_1_clip-path-9">
          <path
            className="noise_slide_1_cls-2"
            d="M860.95 286.25l-39.78-.99 4.26 20.29 35.52.01v-19.31z"
          />
        </clipPath>
        <clipPath id="noise_slide_1_clip-path-10">
          <path
            className="noise_slide_1_cls-2"
            d="M783.57 286.25l-39.78-.99 4.27 20.29 35.51.01v-19.31z"
          />
        </clipPath>
        <clipPath id="noise_slide_1_clip-path-11">
          <rect
            className="noise_slide_1_cls-2"
            x={818.04}
            y={205.75}
            width={18.62}
            height={18.62}
            rx={8.05}
          />
        </clipPath>
        <linearGradient
          id="noise_slide_1_linear-gradient"
          x1={825.96}
          y1={217.68}
          x2={826.59}
          y2={274.46}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#80cfd3" />
          <stop offset={1} stopColor="#c0ffff" />
        </linearGradient>
        <style>
          {
            '.noise_slide_1_cls-1{fill:#ed6bb0}.noise_slide_1_cls-13,.noise_slide_1_cls-15,.noise_slide_1_cls-19,.noise_slide_1_cls-2{fill:none}.noise_slide_1_cls-5{clip-path:url(#noise_slide_1_clip-path)}.noise_slide_1_cls-7{fill:#80cfd4}.noise_slide_1_cls-8{fill:#d9498f}.noise_slide_1_cls-10{fill:#dde1e4}.noise_slide_1_cls-13{stroke:#855bb5;stroke-width:1.56px}.noise_slide_1_cls-13,.noise_slide_1_cls-15,.noise_slide_1_cls-19{stroke-linecap:round;stroke-linejoin:round}.noise_slide_1_cls-14{fill:#62b0b2}.noise_slide_1_cls-15,.noise_slide_1_cls-19{stroke:#ffe26e}.noise_slide_1_cls-15{stroke-width:2.35px}.noise_slide_1_cls-18{fill:#3f3461}.noise_slide_1_cls-19{stroke-width:2.58px}.noise_slide_1_cls-24{fill:#af4d94}.noise_slide_1_cls-32{opacity:.5;fill:#80cfd3}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="noise_slide_1_Layer_1" data-name="Layer 1">
          <g id="noise_slide_1_elements_to_animate">
            <g id="noise_slide_1_people_talking-2" data-name="people_talking">
              <path
                className="noise_slide_1_cls-1"
                d="M438.07 446.58c5.37 0 11.61-.72 15.74-3.52 6.89-4.65 10.19-13.42 10.19-22.36v-22.78A25.89 25.89 0 00438.07 372a25.88 25.88 0 00-25.88 25.88v22.82a25.88 25.88 0 0025.88 25.88z"
              />
              <g clipPath="url(#noise_slide_1_clip-path-2)">
                <rect
                  className="noise_slide_1_cls-7"
                  x={406.19}
                  y={366.04}
                  width={63.78}
                  height={86.55}
                  rx={26.77}
                />
                <path
                  className="noise_slide_1_cls-8"
                  d="M462 437.17a.59.59 0 01-.78.37c-1.56-.62-5.9-2.5-8.12-5.18-2-2.48-2.79-6.49-3-8.33a.58.58 0 01.79-.62 46.67 46.67 0 008.61 2.47 19.19 19.19 0 005.22.28.59.59 0 01.63.77z"
                />
              </g>
              <path
                className="noise_slide_1_cls-7"
                d="M458.78 423.19H482a1.55 1.55 0 001-2.72l-21.88-18.87a1.55 1.55 0 00-2.56 1.06l-1.32 18.87a1.55 1.55 0 001.54 1.66z"
              />
              <g clipPath="url(#noise_slide_1_clip-path-3)">
                <ellipse
                  className="noise_slide_1_cls-10"
                  cx={448.22}
                  cy={403.1}
                  rx={7.25}
                  ry={10.15}
                />
                <ellipse
                  cx={452.75}
                  cy={404.76}
                  rx={5.56}
                  ry={6}
                  fill="#32294d"
                />
              </g>
              <g>
                <g clipPath="url(#noise_slide_1_clip-path-4)">
                  <rect
                    className="noise_slide_1_cls-7"
                    x={497.1}
                    y={395.85}
                    width={63.78}
                    height={86.55}
                    rx={26.77}
                  />
                  <path
                    className="noise_slide_1_cls-1"
                    d="M542.65 450.93l-26.5 5.8s2.48 10.77 14.9 7.87 11.6-13.67 11.6-13.67z"
                  />
                </g>
                <path
                  className="noise_slide_1_cls-13"
                  d="M544.72 435s-1.72-5.17-7-4.23-4.85 5.63-4.85 5.63M520.29 437.93s-1.73-5.16-7-4.22-4.85 5.63-4.85 5.63"
                />
                <path
                  className="noise_slide_1_cls-14"
                  d="M528 437.63l.9 10.31a.88.88 0 01-.56 1l-9.09 2.1c-.63.14-1-.81-.64-1.44l8.2-12.41c.39-.61 1.13-.32 1.19.44z"
                />
                <path
                  className="noise_slide_1_cls-7"
                  d="M505 449.69h-4.77a8.48 8.48 0 01-8.48-8.48v-2.74a9.07 9.07 0 019.08-9.07H505z"
                />
                <path
                  className="noise_slide_1_cls-7"
                  d="M506 455.07h-3.49a6 6 0 01-6-6v-3.68a2.77 2.77 0 012.77-2.76H506zM553.18 443.89H558a8.47 8.47 0 008.48-8.47v-2.75a9.07 9.07 0 00-9.07-9.07h-4.18z"
                />
                <path
                  className="noise_slide_1_cls-7"
                  d="M552.17 449.28h3.49a6 6 0 006-6v-3.68a2.77 2.77 0 00-2.76-2.77h-6.71z"
                />
              </g>
              <g className="talking-lines">
                <path
                  className="noise_slide_1_cls-15"
                  d="M486.8 429.3l-17.08.67"
                />
                <path
                  className="noise_slide_1_cls-15"
                  d="M485.09 440.1l-16.04-5.92"
                />
                <path
                  className="noise_slide_1_cls-15"
                  d="M479.38 449.42l-12.56-11.61"
                />
              </g>
            </g>
            <g id="noise_slide_1_mute">
              <path
                className="noise_slide_1_cls-7"
                d="M328.76 624.75a27.28 27.28 0 00-27.28-27.29h-10.22a4.94 4.94 0 00-4.93 4.94v56.91a4.94 4.94 0 004.93 4.94h3.42a18.93 18.93 0 0018.7-16.09 25.5 25.5 0 0015.38-23.41z"
              />
              <path
                d="M312.64 640.7s10.34-3.76 9.4-18.8-21.62-19.74-21.62-19.74"
                stroke="#64b2b2"
                strokeWidth={4.7}
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
              <path
                stroke="#ed6bb0"
                strokeMiterlimit={10}
                strokeWidth={10.34}
                fill="none"
                d="M341.78 590.88l-75.86 85.53"
              />
            </g>
            <g id="noise_slide_1_fugl-2" data-name="fugl">
              <path
                className="noise_slide_1_cls-18"
                transform="rotate(-86.04 666.059 380.65)"
                d="M656.02 378.82h20.16v3.62h-20.16z"
              />
              <path
                className="noise_slide_1_cls-18"
                transform="rotate(-86.04 658.829 380.147)"
                d="M648.79 378.32h20.16v3.62h-20.16z"
              />
              <g
                className="beak"
              >
                <path
                  className="noise_slide_1_cls-14"
                  d="M592.31 316.12l53-10.51-4.14-18.5-72 14.28a19.75 19.75 0 0023.14 14.73z"
                />
              </g>
              <path
                className="noise_slide_1_cls-7"
                d="M590.2 259.77l49.8 21.59c-4.27 9.51-15.72 13.76-25.58 9.48l-49.8-21.59c4.27-9.51 15.72-13.76 25.58-9.48z"
              />
              <path
                className="noise_slide_1_cls-8"
                d="M658.47 336.78l-1-.22-2.88-.63 4.8-21.83c3.37-15.37-6.77-30.66-22.65-34.14l-10.9 49.66-4.68 21.29A13.87 13.87 0 00632 367.3l54.42 12c4.17-19.2-8.33-38.21-27.95-42.52z"
              />
              <path
                className="noise_slide_1_cls-1"
                d="M646.56 343.46l7.26-7.73c13.54-14.42 36.7-15.29 51.74-2l-7.26 7.74c-13.54 14.41-36.71 15.28-51.74 1.94z"
              />
              <g className="noise-lines">
                <path
                  className="noise_slide_1_cls-19"
                  d="M542.3 308.38l13.62-12.98"
                />
                <path
                  className="noise_slide_1_cls-19"
                  d="M533.8 286.36l18.81.46"
                />
                <path
                  className="noise_slide_1_cls-19"
                  d="M543.37 264.79l12.97 13.62"
                />
              </g>
              <g id="noise_slide_1_eye_frog-2" data-name="eye frog">
                <g className="noise_slide_1_cls-5">
                  <path
                    d="M638 295.51a7.37 7.37 0 107.36-7.36 7.36 7.36 0 00-7.36 7.36"
                    fill="#fff"
                  />
                  <g clipPath="url(#noise_slide_1_clip-path-7)">
                    <path d="M633.65 293.44a7.37 7.37 0 107.36-7.37 7.35 7.35 0 00-7.36 7.37" />
                  </g>
                </g>
              </g>
            </g>
            <g id="noise_slide_1_jet-2" data-name="jet">
              <path
                className="noise_slide_1_cls-8"
                d="M1056.4 173.88h-56.49c-2.3 0-4.28-1.89-4.83-4.59l-18.36-90.88c-.44-2.19 1.58-4.32 4-4.32h40.85a4.06 4.06 0 013.78 2.46l34.72 89.38c1.58 3.95-.26 7.95-3.67 7.95z"
              />
              <rect
                className="noise_slide_1_cls-1"
                x={955.02}
                y={92.86}
                width={110.28}
                height={64.62}
                rx={27.94}
              />
              <rect
                x={955.02}
                y={92.86}
                width={70.46}
                height={64.62}
                rx={27.94}
                fill="#f492ca"
              />
              <g clipPath="url(#noise_slide_1_clip-path-8)">
                <circle
                  className="noise_slide_1_cls-24"
                  cx={986.47}
                  cy={125.17}
                  r={27.14}
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-1.87 986.041 157.059)"
                  d="M984.46 126.46h4.31v61.17h-4.31z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-61.87 971.071 153.43)"
                  d="M940.49 151.27h61.17v4.31h-61.17z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-31.87 959.413 142.527)"
                  d="M928.85 140.36h61.17v4.31h-61.17z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-1.87 954.352 127.123)"
                  d="M924.22 125.1h61.17v4.31h-61.17z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-61.87 958.423 111.718)"
                  d="M956.28 81.13h4.31v61.17h-4.31z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-31.87 969.319 100.07)"
                  d="M967.19 69.49h4.31v61.17h-4.31z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="translate(-2.59 32.17)"
                  d="M982.45 64.86h4.31v61.17h-4.31z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-61.87 1000.13 99.078)"
                  d="M969.55 96.91h61.17v4.31h-61.17z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-31.87 1011.757 109.971)"
                  d="M981.2 107.82h61.17v4.31H981.2z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-1.87 1015.982 125.064)"
                  d="M985.83 123.09H1047v4.31h-61.17z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-61.87 1012.775 140.777)"
                  d="M1010.64 110.19h4.31v61.17h-4.31z"
                />
                <path
                  className="noise_slide_1_cls-8"
                  transform="rotate(-31.87 1001.851 152.428)"
                  d="M999.73 121.83h4.31V183h-4.31z"
                />
                <rect
                  className="noise_slide_1_cls-1"
                  x={972.26}
                  y={114.4}
                  width={22.4}
                  height={22.4}
                  rx={9.69}
                />
              </g>
              <rect
                x={1010.17}
                y={98.03}
                width={33.6}
                height={3.45}
                rx={1.49}
                opacity={0.5}
                fill="#dde1e4"
              />
              <path
                className="noise_slide_1_cls-19"
                d="M951 107.69c-7 0-7-5.17-14-5.17s-7 5.17-14 5.17-7-5.17-14-5.17-7 5.17-14 5.17-7-5.17-14-5.17"
              />
              <path
                className="noise_slide_1_cls-19"
                d="M934.68 129.9c-7 0-7-5.17-14-5.17s-7 5.17-14 5.17-7-5.17-14-5.17-7 5.17-14 5.17-7-5.17-14-5.17"
              />
              <path
                className="noise_slide_1_cls-19"
                d="M951 156.15c-7 0-7-5.17-14-5.17s-7 5.17-14 5.17-7-5.17-14-5.17-7 5.17-14 5.17-7-5.17-14-5.17"
              />
            </g>
            <g id="noise_slide_1_ambulance-2" data-name="ambulance">
              <path
                className="noise_slide_1_cls-8"
                d="M869.86 300.49V303a2.57 2.57 0 01-2.57 2.57H740.55A2.56 2.56 0 01738 303v-2.5a2.56 2.56 0 002.55 2.57h126.74a2.57 2.57 0 002.57-2.58z"
              />
              <path
                className="noise_slide_1_cls-1"
                d="M869.86 279.78v20.71a2.57 2.57 0 01-2.57 2.57H740.55a2.56 2.56 0 01-2.55-2.57v-70.61a2.56 2.56 0 012.55-2.56h86.83a2.57 2.57 0 012.41 1.68l15 40.88a2.53 2.53 0 001.74 1.59l21.47 5.84a2.56 2.56 0 011.86 2.47z"
              />
              <g clipPath="url(#noise_slide_1_clip-path-9)">
                <ellipse
                  className="noise_slide_1_cls-8"
                  cx={842.38}
                  cy={303.72}
                  rx={16.7}
                  ry={16.16}
                />
              </g>
              <g id="noise_slide_1_hjul">
                <ellipse
                  className="noise_slide_1_cls-24"
                  cx={842.38}
                  cy={303.61}
                  rx={14.66}
                  ry={14.18}
                />
                <ellipse
                  className="noise_slide_1_cls-8"
                  cx={842.38}
                  cy={303.72}
                  rx={9.02}
                  ry={8.73}
                />
              </g>
              <g clipPath="url(#noise_slide_1_clip-path-10)">
                <ellipse
                  className="noise_slide_1_cls-8"
                  cx={765.01}
                  cy={303.72}
                  rx={16.7}
                  ry={16.16}
                />
              </g>
              <g id="noise_slide_1_hjul-2" data-name="hjul">
                <ellipse
                  className="noise_slide_1_cls-24"
                  cx={765.01}
                  cy={303.61}
                  rx={14.66}
                  ry={14.18}
                />
                <ellipse
                  className="noise_slide_1_cls-8"
                  cx={765.01}
                  cy={303.72}
                  rx={9.02}
                  ry={8.73}
                />
              </g>
              <path
                d="M844.74 269.78l-33.65-.28a2.79 2.79 0 01-2.79-2.79v-31a2.79 2.79 0 012.79-2.79h20.12z"
                fill="url(#noise_slide_1_linear-gradient)"
              />
              <path
                d="M854.38 278.15H862a5.53 5.53 0 015.53 5.53v.86h-11.64a1.51 1.51 0 01-1.51-1.51z"
                fill="#ffe26e"
              />
              <rect
                className="noise_slide_1_cls-10"
                x={747.71}
                y={250.83}
                width={34.43}
                height={9.18}
                rx={1.88}
              />
              <rect
                className="noise_slide_1_cls-10"
                x={760.33}
                y={238.21}
                width={9.18}
                height={34.43}
                rx={1.88}
              />
              <path
                className="noise_slide_1_cls-1"
                d="M799.24 204.54h9a2.75 2.75 0 012.75 2.75v20h-14.5v-20a2.75 2.75 0 012.75-2.75z"
              />
              <path
                className="noise_slide_1_cls-8"
                d="M799.3 204.54h.88a2.81 2.81 0 012.81 2.81v20h-6.5v-20a2.81 2.81 0 012.81-2.81z"
              />
              <rect
                x={782.75}
                y={213.42}
                width={21.18}
                height={11.7}
                rx={4.7}
                fill="#5d9fd3"
              />
              <rect
                x={777.01}
                y={213.42}
                width={21.18}
                height={11.7}
                rx={4.7}
                fill="#80cfd3"
              />
              <g className="ambulance-light">
                <g>
                  <rect
                    className="noise_slide_1_cls-32"
                    x={769.62}
                    y={209.34}
                    width={36.17}
                    height={19.98}
                    rx={8.02}
                  />
                </g>
                <g>
                  <rect
                    className="noise_slide_1_cls-32"
                    x={762.15}
                    y={205.21}
                    width={50.14}
                    height={27.7}
                    rx={11.98}
                  />
                </g>
                <g>
                  <rect
                    className="noise_slide_1_cls-8"
                    x={816.83}
                    y={204.54}
                    width={21.04}
                    height={21.04}
                    rx={9.1}
                  />
                </g>
              </g>
              <g>
                <path
                  className="noise_slide_1_cls-8"
                  d="M823.82 225l-13.46-5.18a5.07 5.07 0 01-3.25-4.74 5.07 5.07 0 013.25-4.74l13-5z"
                />
                <g clipPath="url(#noise_slide_1_clip-path-11)">
                  <rect
                    className="noise_slide_1_cls-24"
                    x={818.04}
                    y={205.75}
                    width={18.62}
                    height={18.62}
                    rx={8.05}
                  />
                  <rect
                    className="noise_slide_1_cls-1"
                    x={814.85}
                    y={211.04}
                    width={14.91}
                    height={7.36}
                    rx={3.18}
                  />
                </g>
              </g>
              <g className="noise-linse">
                <path
                  className="noise_slide_1_cls-19"
                  d="M857.25 191.95l-11.82 14.63"
                />
                <path
                  className="noise_slide_1_cls-19"
                  d="M868.53 212.67l-18.71 1.99"
                />
                <path
                  className="noise_slide_1_cls-19"
                  d="M861.85 235.31l-14.63-11.83"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NoiseNoAnimationSlide1;
