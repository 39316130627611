import * as React from 'react';
import { motion } from 'framer-motion';

function NoiseAnimationSlide4(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="noise_slide_4__clip-path">
          <rect
            className="noise_slide_4__cls-1"
            x={825.42}
            y={493.82}
            width={61.83}
            height={28.21}
            rx={10.22}
            transform="rotate(-14.73 856.304 507.907)"
          />
        </clipPath>
        <clipPath id="noise_slide_4__clip-path-2">
          <path
            className="noise_slide_4__cls-1"
            d="M867.05 499.07a7.45 7.45 0 115.58 9 7.49 7.49 0 01-5.58-9"
          />
        </clipPath>
        <clipPath id="noise_slide_4__clip-path-3">
          <circle className="noise_slide_4__cls-1" cx={118.37} cy={658.95} r={6.55} />
        </clipPath>
        <clipPath id="noise_slide_4__clip-path-4">
          <circle className="noise_slide_4__cls-1" cx={178.31} cy={725.76} r={3} />
        </clipPath>
        <clipPath id="noise_slide_4__clip-path-5">
          <circle className="noise_slide_4__cls-1" cx={222.88} cy={741.48} r={3} />
        </clipPath>
        <clipPath id="noise_slide_4__clip-path-6">
          <circle className="noise_slide_4__cls-1" cx={279.37} cy={729.71} r={3} />
        </clipPath>
        <style>
          {
            '.noise_slide_4__cls-1{fill:none}.noise_slide_4__cls-5{fill:#855bb5}.noise_slide_4__cls-14{fill:#ffe26e}.noise_slide_4__cls-16{fill:#eb826a}.noise_slide_4__cls-20{fill:#414042}.noise_slide_4__cls-24{fill:#ff9e41}.noise_slide_4__cls-25{fill:#ffd45d}.noise_slide_4__cls-26{fill:#f8f6c7}.noise_slide_4__cls-28{fill:#fff}.noise_slide_4__cls-29{fill:#3b2314}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="noise_slide_4__Layer_1" data-name="Layer 1">
          <motion.g
            id="noise_slide_4__bird"
            animate={{
              rotate: ['5deg', '0deg', '-5deg', '0deg', '5deg'],
            }}
            transition={{
              duration: 5, repeat: Infinity, ease: 'linear',
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'bottom',
            }}
          >
            <motion.path
              animate={{
                rotate: ['-20deg', '0deg', '0deg', '0deg', '-20deg'],
              }}
              transition={{
                duration: 5, repeat: Infinity,
              }}
              style={{
                transformBox: 'fill-box',
                originX: 'right',
                originY: 'center',
              }}
              id="noise_slide_4__arm"
              d="M1177.79 625.64l-44.1-15.08a27.37 27.37 0 01-17-34.77l3-8.76a3.29 3.29 0 014.16-2l66.9 22.88a3.28 3.28 0 012 4.17L1182 623.6a3.27 3.27 0 01-4.21 2.04z"
              fill="#3b639e"
            />
            <path
              id="noise_slide_4__leg"
              className="noise_slide_4__cls-5"
              d="M1205.39 635.85h5.17v24.45h-5.17z"
            />
            <path
              id="noise_slide_4__leg-2"
              data-name="leg"
              className="noise_slide_4__cls-5"
              d="M1196.95 636.02h5.17v24.45h-5.17z"
            />
            <rect
              x={1149.27}
              y={517.4}
              width={23.73}
              height={6.83}
              rx={2.65}
              transform="rotate(-38.13 1161.205 520.817)"
              fill="#f4cb5d"
            />
            <rect
              x={1155.64}
              y={494.48}
              width={6.83}
              height={23.73}
              rx={2.65}
              transform="rotate(-56.37 1159.053 506.343)"
              fill="#fced6f"
            />
            <path
              d="M1159.85 585.59l48.45 3.08 5.18-81.54a18.86 18.86 0 00-17.63-20l-10.82-.68a18.85 18.85 0 00-20 17.62z"
              fill="#6bb8ed"
            />
            <path
              d="M1250.74 591.36l-31.53-2-1.46-.07-57.9-3.68-2.17 34.14a13.31 13.31 0 0012.45 14.13l5.59.36 39 2.47 3 .19a36.61 36.61 0 0038.86-34.22l.27-4.35a6.55 6.55 0 00-6.11-6.97z"
              fill="#5d9fd3"
            />
            <path
              transform="rotate(-86.37 1184.835 575.103)"
              fill="#7dd3f4"
              d="M1172.78 550.82h24.11v48.56h-24.11z"
            />
            <motion.path
              animate={{
                rotate: ['20deg', '0deg', '0deg', '0deg', '20deg'],
              }}
              transition={{
                duration: 5, repeat: Infinity,
              }}
              style={{
                transformBox: 'fill-box',
                originX: 'right',
                originY: 'center',
              }}
              id="noise_slide_4__arm-2"
              data-name="arm"
              d="M1206.52 643.82l12.59-8.42a18.46 18.46 0 005.08-25.6l-5.91-8.82a18.45 18.45 0 00-25.6-5.08l-38.77 25.94a2.7 2.7 0 00-.74 3.75l5.9 8.82a34.2 34.2 0 0047.45 9.41z"
              fill="#4d81ba"
            />
            <path
              d="M1198.51 515.88s-.54-10.49-9.63-11.06-10.12 9.8-10.12 9.8"
              strokeLinecap="round"
              stroke="#4d81ba"
              strokeMiterlimit={10}
              strokeWidth={2.98}
              fill="none"
            />
          </motion.g>
          <motion.g
            className="music-note"
            animate={{
              x: [100, 0, -150],
              y: [0, 0, -550],
              opacity: [0, 1, 1, 1, 1, 0],
              scale: [0, 1, 0],
            }}
            transition={{
              duration: 6, repeat: Infinity, ease: 'linear',
            }}
          >
            <path
              strokeWidth={3.63}
              stroke="#ffe26e"
              strokeLinejoin="round"
              strokeLinecap="round"
              fill="none"
              d="M1056.32 513.86l8.86-29.24 28.38 8.07-7.26 28.03"
            />
            <circle
              className="noise_slide_4__cls-14"
              cx={1081.9}
              cy={519.72}
              r={6.35}
            />
            <circle
              className="noise_slide_4__cls-14"
              cx={1052.15}
              cy={512.26}
              r={6.35}
            />
          </motion.g>
          <motion.g
            className="music-note"
            animate={{
              x: [100, 0, -150],
              y: [0, 0, -550],
              opacity: [0, 1, 1, 1, 1, 0],
              scale: [0, 1, 0],
            }}
            transition={{
              delay: 2, duration: 6, repeat: Infinity, ease: 'linear',
            }}
          >
            <path
              strokeWidth={3.63}
              stroke="#ffe26e"
              strokeLinejoin="round"
              strokeLinecap="round"
              fill="none"
              d="M1056.32 513.86l8.86-29.24 28.38 8.07-7.26 28.03"
            />
            <circle
              className="noise_slide_4__cls-14"
              cx={1081.9}
              cy={519.72}
              r={6.35}
            />
            <circle
              className="noise_slide_4__cls-14"
              cx={1052.15}
              cy={512.26}
              r={6.35}
            />
          </motion.g>
          <motion.g
            className="music-note"
            animate={{
              x: [100, 0, -150],
              y: [0, 0, -550],
              opacity: [0, 1, 1, 1, 1, 0],
              scale: [0, 1, 0],
            }}
            transition={{
              delay: 4, duration: 6, repeat: Infinity, ease: 'linear',
            }}
          >
            <path
              strokeWidth={3.63}
              stroke="#ffe26e"
              strokeLinejoin="round"
              strokeLinecap="round"
              fill="none"
              d="M1056.32 513.86l8.86-29.24 28.38 8.07-7.26 28.03"
            />
            <circle
              className="noise_slide_4__cls-14"
              cx={1081.9}
              cy={519.72}
              r={6.35}
            />
            <circle
              className="noise_slide_4__cls-14"
              cx={1052.15}
              cy={512.26}
              r={6.35}
            />
          </motion.g>
          <g
            id="noise_slide_4__bee"
            transform="translate(-1000, -150)"
          >
            <animateMotion
              dur="3s"
              repeatCount="indefinite"
              path="M956.4,126c-3,19.1,11.67,38.06,30.89,39.07,17.29.9,32.44-13,34.53-30,1.94-15.76-7.68-31.76-22.72-36.35C980.74,93.18,959.56,106.21,956.4,126Z"
            />
            <path
              className="noise_slide_4__cls-16"
              d="M866.79 527.12l2.59-.64.02-7.54-2.58.63-.03 7.55zM861.88 528.32l2.59-.63.02-7.55-2.58.64-.03 7.54zM850.51 531.11l2.59-.63.02-7.55-2.58.64-.03 7.54zM845.6 532.31l2.59-.63.02-7.54-2.58.63-.03 7.54z"
            />
            <rect
              id="noise_slide_4__rec_yellow"
              data-name="rec yellow"
              x={825.42}
              y={493.82}
              width={61.83}
              height={28.21}
              rx={10.22}
              transform="rotate(-14.73 856.304 507.907)"
              fill="#fbec6d"
            />
            <path
              id="noise_slide_4__rec_orange"
              d="M845.48 525.35l10.52-2.76 9.33-2.45-7.17-27.28-10.8 2.84-9 2.37z"
              fill="#f99c43"
            />
            <g clipPath="url(#noise_slide_4__clip-path)">
              <path
                className="noise_slide_4__cls-20"
                d="M896.32 510a12.06 12.06 0 01-20.32 0l-1.33.84a13.63 13.63 0 0023 0z"
              />
            </g>
            <g clipPath="url(#noise_slide_4__clip-path-2)">
              <path
                d="M867.05 499.07a7.45 7.45 0 115.58 9 7.49 7.49 0 01-5.58-9"
                fill="#f1f2f2"
              />
              <path
                className="noise_slide_4__cls-20"
                d="M874.31 500.72a7.44 7.44 0 115.58 9 7.49 7.49 0 01-5.58-9"
              />
            </g>
            <g>
              <motion.g
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
              >
                <path
                  id="noise_slide_4__rec_2"
                  data-name="rec 2"
                  className="noise_slide_4__cls-16"
                  d="M813.67 470.84h10.57a21.06 21.06 0 0121.06 21.06v8h-10.57a21.06 21.06 0 01-21.06-21.06v-8z"
                  transform="rotate(-14.73 829.446 485.36)"
                />
              </motion.g>
              <motion.g
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
              >
                <path
                  id="noise_slide_4__rec_1"
                  data-name="rec 1"
                  d="M839.11 462.82h10.57a21.06 21.06 0 0121.06 21.06v8h-10.57a21.06 21.06 0 01-21.06-21.06v-8z"
                  transform="rotate(75.27 854.93 477.357)"
                  fill="#f5b1a6"
                />
              </motion.g>
            </g>
          </g>
          <g id="noise_slide_4__ducks">
            <motion.g
              animate={{
                x: [0, 70, 60, 150, 110, 150, 0],
              }}
              transition={{
                duration: 10, repeat: Infinity, repeatDelay: 10,
              }}
              id="noise_slide_4__duck"
            >
              <g id="noise_slide_4__HEAD">
                <path
                  id="noise_slide_4__head_shape"
                  data-name="head shape"
                  className="noise_slide_4__cls-24"
                  d="M119.24 644.85A17.26 17.26 0 00102 662.09v31.48a.79.79 0 00.79.78H134a.78.78 0 00.79-.78v-33.22a15.51 15.51 0 00-15.55-15.5z"
                />
                <path
                  id="noise_slide_4__under_neeb"
                  data-name="under neeb"
                  className="noise_slide_4__cls-25"
                  d="M145.19 659.85h-15a.79.79 0 00-.79.79v7.21a.79.79 0 00.79.79h9.26a6.49 6.49 0 006.49-6.48v-1.52a.79.79 0 00-.75-.79z"
                />
                <path
                  id="noise_slide_4__top_neeb"
                  data-name="top neeb"
                  className="noise_slide_4__cls-14"
                  d="M150 659.85h-19.77a.79.79 0 00-.79.79v.76a7.25 7.25 0 007.24 7.24h7.63a7.25 7.25 0 007.24-7.24 1.55 1.55 0 00-1.55-1.55z"
                />
                <path
                  id="noise_slide_4__neck"
                  className="noise_slide_4__cls-26"
                  d="M102 684.55h32.74v9.81H102z"
                />
                <g
                  clipPath="url(#noise_slide_4__clip-path-3)"
                  id="noise_slide_4__eye_grp"
                  data-name="eye grp"
                >
                  <circle
                    className="noise_slide_4__cls-28"
                    cx={118.37}
                    cy={658.95}
                    r={6.55}
                    id="noise_slide_4__eye_shape"
                    data-name="eye shape"
                  />
                  <circle
                    id="noise_slide_4__iris"
                    className="noise_slide_4__cls-29"
                    cx={120.79}
                    cy={657.52}
                    r={5.21}
                  />
                </g>
              </g>
              <path
                id="noise_slide_4__body"
                className="noise_slide_4__cls-14"
                d="M137.34 693.74H95.7c-22.46 0-56.89 18.14-56.89 40.49a.78.78 0 00.79.78h91.33A10.1 10.1 0 00141 724.92v-27.51a3.68 3.68 0 00-3.66-3.67z"
              />
              <path
                id="noise_slide_4__wing"
                className="noise_slide_4__cls-25"
                d="M125.86 693.74H86.45c-22 0-47.64 17.68-47.64 40.49a.78.78 0 00.79.78h53a34 34 0 0034-34v-6.48a.79.79 0 00-.74-.79z"
              />
            </motion.g>
            <motion.g
              animate={{
                x: [0, 70, 60, 150, 110, 150, 0],
              }}
              transition={{
                duration: 10, repeat: Infinity, repeatDelay: 10,
              }}
              id="noise_slide_4__duck-2"
              data-name="duck"
            >
              <g id="noise_slide_4__HEAD-2" data-name="HEAD">
                <path
                  id="noise_slide_4__head_shape-2"
                  data-name="head shape"
                  className="noise_slide_4__cls-24"
                  d="M178.71 719.3a7.9 7.9 0 00-7.89 7.9v14.42a.36.36 0 00.36.36h14.27a.36.36 0 00.36-.36V726.4a7.1 7.1 0 00-7.1-7.1z"
                />
                <path
                  id="noise_slide_4__under_neeb-2"
                  data-name="under neeb"
                  className="noise_slide_4__cls-25"
                  d="M190.6 726.17h-6.85a.37.37 0 00-.37.36v3.31a.37.37 0 00.37.36H188a3 3 0 003-3v-.7a.36.36 0 00-.4-.33z"
                />
                <path
                  id="noise_slide_4__top_neeb-2"
                  data-name="top neeb"
                  className="noise_slide_4__cls-14"
                  d="M192.81 726.17h-9.06a.37.37 0 00-.37.36v.35a3.32 3.32 0 003.32 3.32h3.5a3.32 3.32 0 003.31-3.32.7.7 0 00-.7-.71z"
                />
                <path
                  id="noise_slide_4__neck-2"
                  data-name="neck"
                  className="noise_slide_4__cls-26"
                  d="M170.82 737.49h15v4.49h-15z"
                />
                <g
                  clipPath="url(#noise_slide_4__clip-path-4)"
                  id="noise_slide_4__eye_grp-2"
                  data-name="eye grp"
                >
                  <circle
                    className="noise_slide_4__cls-28"
                    cx={178.31}
                    cy={725.76}
                    r={3}
                    id="noise_slide_4__eye_shape-2"
                    data-name="eye shape"
                  />
                  <circle
                    id="noise_slide_4__iris-2"
                    data-name="iris"
                    className="noise_slide_4__cls-29"
                    cx={179.42}
                    cy={725.11}
                    r={2.39}
                  />
                </g>
              </g>
              <path
                id="noise_slide_4__body-2"
                data-name="body"
                className="noise_slide_4__cls-14"
                d="M186.15 741.7h-19.07c-10.29 0-26.06 8.3-26.06 18.54a.36.36 0 00.36.36h41.83a4.63 4.63 0 004.63-4.62v-12.6a1.69 1.69 0 00-1.69-1.68z"
              />
              <path
                id="noise_slide_4__wing-2"
                data-name="wing"
                className="noise_slide_4__cls-25"
                d="M180.9 741.7h-18.06c-10.09 0-21.82 8.1-21.82 18.54a.36.36 0 00.36.36h24.3a15.59 15.59 0 0015.58-15.6v-3a.36.36 0 00-.36-.3z"
              />
            </motion.g>
            <motion.g
              animate={{
                x: [0, 70, 60, 150, 110, 150, 0],
              }}
              transition={{
                duration: 10, repeat: Infinity, repeatDelay: 10,
              }}
              id="noise_slide_4__duck-3"
              data-name="duck"
            >
              <g id="noise_slide_4__HEAD-3" data-name="HEAD">
                <path
                  id="noise_slide_4__head_shape-3"
                  data-name="head shape"
                  className="noise_slide_4__cls-24"
                  d="M223.28 735a7.91 7.91 0 00-7.9 7.9v14.42a.36.36 0 00.36.36H230a.36.36 0 00.36-.36v-15.21a7.11 7.11 0 00-7.08-7.11z"
                />
                <path
                  id="noise_slide_4__under_neeb-3"
                  data-name="under neeb"
                  className="noise_slide_4__cls-25"
                  d="M235.17 741.89h-6.86a.36.36 0 00-.36.36v3.3a.36.36 0 00.36.36h4.25a3 3 0 003-3v-.69a.36.36 0 00-.39-.33z"
                />
                <path
                  id="noise_slide_4__top_neeb-3"
                  data-name="top neeb"
                  className="noise_slide_4__cls-14"
                  d="M237.37 741.89h-9.06a.36.36 0 00-.36.36v.35a3.32 3.32 0 003.32 3.31h3.49a3.32 3.32 0 003.32-3.31.71.71 0 00-.71-.71z"
                />
                <path
                  id="noise_slide_4__neck-3"
                  data-name="neck"
                  className="noise_slide_4__cls-26"
                  d="M215.38 753.2h15v4.49h-15z"
                />
                <g
                  clipPath="url(#noise_slide_4__clip-path-5)"
                  id="noise_slide_4__eye_grp-3"
                  data-name="eye grp"
                >
                  <circle
                    className="noise_slide_4__cls-28"
                    cx={222.88}
                    cy={741.48}
                    r={3}
                    id="noise_slide_4__eye_shape-3"
                    data-name="eye shape"
                  />
                  <circle
                    id="noise_slide_4__iris-3"
                    data-name="iris"
                    className="noise_slide_4__cls-29"
                    cx={223.99}
                    cy={740.82}
                    r={2.39}
                  />
                </g>
              </g>
              <path
                id="noise_slide_4__body-3"
                data-name="body"
                className="noise_slide_4__cls-14"
                d="M230.72 757.41h-19.07c-10.3 0-26.07 8.31-26.07 18.55a.37.37 0 00.37.36h41.83a4.63 4.63 0 004.62-4.62v-12.61a1.68 1.68 0 00-1.68-1.68z"
              />
              <path
                id="noise_slide_4__wing-3"
                data-name="wing"
                className="noise_slide_4__cls-25"
                d="M225.46 757.41h-18.05c-10.09 0-21.83 8.1-21.83 18.55a.37.37 0 00.37.36h24.29a15.59 15.59 0 0015.58-15.58v-3a.36.36 0 00-.36-.33z"
              />
            </motion.g>
            <motion.g
              animate={{
                x: [0, 70, 60, 150, 110, 150, 0],
              }}
              transition={{
                duration: 10, repeat: Infinity, repeatDelay: 10,
              }}
              id="noise_slide_4__duck-4"
              data-name="duck"
            >
              <g id="noise_slide_4__HEAD-4" data-name="HEAD">
                <path
                  id="noise_slide_4__head_shape-4"
                  data-name="head shape"
                  className="noise_slide_4__cls-24"
                  d="M279.77 723.25a7.9 7.9 0 00-7.9 7.9v14.42a.36.36 0 00.36.36h14.27a.36.36 0 00.36-.36v-15.22a7.1 7.1 0 00-7.09-7.1z"
                />
                <path
                  id="noise_slide_4__under_neeb-4"
                  data-name="under neeb"
                  className="noise_slide_4__cls-25"
                  d="M291.65 730.12h-6.85a.36.36 0 00-.36.36v3.31a.36.36 0 00.36.36h4.2a3 3 0 003-3v-.7a.36.36 0 00-.35-.33z"
                />
                <path
                  id="noise_slide_4__top_neeb-4"
                  data-name="top neeb"
                  className="noise_slide_4__cls-14"
                  d="M293.86 730.12h-9.06a.36.36 0 00-.36.36v.35a3.32 3.32 0 003.32 3.32h3.49a3.32 3.32 0 003.32-3.32.71.71 0 00-.71-.71z"
                />
                <path
                  id="noise_slide_4__neck-4"
                  data-name="neck"
                  className="noise_slide_4__cls-26"
                  d="M271.87 741.44h15v4.49h-15z"
                />
                <g
                  clipPath="url(#noise_slide_4__clip-path-6)"
                  id="noise_slide_4__eye_grp-4"
                  data-name="eye grp"
                >
                  <circle
                    className="noise_slide_4__cls-28"
                    cx={279.37}
                    cy={729.71}
                    r={3}
                    id="noise_slide_4__eye_shape-4"
                    data-name="eye shape"
                  />
                  <circle
                    id="noise_slide_4__iris-4"
                    data-name="iris"
                    className="noise_slide_4__cls-29"
                    cx={280.47}
                    cy={729.05}
                    r={2.39}
                  />
                </g>
              </g>
              <path
                id="noise_slide_4__body-4"
                data-name="body"
                className="noise_slide_4__cls-14"
                d="M287.21 745.64h-19.08c-10.29 0-26.06 8.31-26.06 18.55a.36.36 0 00.36.36h41.84a4.62 4.62 0 004.62-4.62v-12.6a1.69 1.69 0 00-1.68-1.69z"
              />
              <path
                id="noise_slide_4__wing-4"
                data-name="wing"
                className="noise_slide_4__cls-25"
                d="M282 745.64h-18.1c-10.1 0-21.83 8.11-21.83 18.55a.36.36 0 00.36.36h24.3A15.6 15.6 0 00282.31 749v-3a.37.37 0 00-.31-.36z"
              />
            </motion.g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NoiseAnimationSlide4;
